import React, { Component } from "react";
import { connect } from "react-redux";
import moment from "moment";
import {
  reduxForm
} from "redux-form";
import * as _ from "lodash";
import {
  Layout,
  Row,
  Col,
  Input,
  Select,
  List,
  Card,
  Button,
  Icon,
  Spin,
  Skeleton,
  Result,
  Popconfirm,
  message
} from "antd";
import MediaQuery from "react-responsive";
import { numberFormat } from "../../utilities";
import { numFormat } from "../../utilities/format";
import DocumentMeta from "react-document-meta";

import {
  fetchPermissions,
  fetchDrafts, 
  cancelDraft
} from "../../actions";
import swap from "../../assets/icons/swap.svg";
import expandIcon from "../../assets/icons/expand.svg";
import "./Drafts.scss";
const { Option } = Select;

class Drafts extends Component {
  constructor(props) {
    super(props);
    this.state = {
      draft: [],
      expand: [],
      sortBy: "newest",
      XSsorter: false,
      searchValue: "",
      draftSearched: []
    };
    this.imageUploader = React.createRef();
  }

  handleToggleDetails = (id, toggle) => {
    let expand = this.state.expand;

    if (toggle) {
      expand.push(id);
    } else {
      const index = expand.indexOf(id);
      expand.splice(index, 1);
    }

    this.setState({ expand: expand });
  };

  handleSortBy = sortBy => {
    this.setState({
      sortBy,
      XSsorter: true
    });
  };

  handleXSSortBy = sortBy => {
    if (sortBy == "newest") {
      this.setState({
        sortBy,
        XSsorter: false
      });
    } else {
      this.setState({
        sortBy,
        XSsorter: true
      });
    }
  };

  handleSearch = e => {
    const result = Object.values(this.props.draftData.data).filter(row => {
      const search = arg => {
        if (arg) {
          return arg
            .toString()
            .toLowerCase()
            .includes(this.state.searchValue.toString().toLowerCase());
        }
      };
      return [row.product].some(
        row =>
          row
            .map(row => row)
            .map(row => row.product)
            .some(
              row =>
                search(row.name) ||
                search(row.description) ||
                search(row.category) ||
                search(row.country_made) ||
                search(row.brand.name)
            ) ||
          row
            .map(row => row)
            .map(row => row.variant)
            .some(
              row =>
                search(row.name) ||
                search(row.material_code) ||
                // search(row.variants.pricelist_per_distributor[0].special_code) ||
                search(row.packing_scheme) ||
                search(row.variants.size) ||
                search(row.variants.flavor) ||
                search(row.gross_weight) ||
                search(row.net_weight) ||
                search(row.description)
            )
      );
    });

    this.setState({
      searchValue: e.target.value.substr(0, 20),
      draftSearched: result
    });
  };

  searchResultIndicator = () => {
    const { searchValue, draftSearched } = this.state;
    if (searchValue) {
      if (draftSearched.length == 1) {
        return (
          <div className="search-result-indicator">{`${draftSearched.length}  Product Found`}</div>
        );
      } else if (draftSearched.length >= 1) {
        return (
          <div className="search-result-indicator">{`${draftSearched.length}  Products Found`}</div>
        );
      } else {
        return (
          <div className="search-result-indicator">{`${draftSearched.length}  Product Found`}</div>
        );
      }
    }
  };

  componentDidMount() {
    this.props.fetchPermissions();
    this.props.fetchDrafts();
  }

  handleCancelDraft(id) {
    this.props.cancelDraft(id).then(() => {
      message.success('Successfully deleted draft');
    });
  }

  componentDidUpdate(prevProps, prevState) {
    const { draftData } = this.props;
    const { searchValue, draftSearched } = this.state;

    if (this.state.draft !== draftData.data) {
      const expand =
        draftData.data && draftData.data.length > 0
          ? [draftData.data[0].id]
          : [];

      this.setState({
        draft: draftData.data,
        expand: expand
      });
    }

    if (prevState.searchValue !== this.state.searchValue) {
      if (this.state.searchValue.length >= 0) {
        this.setState({
          searchValue,
          draftSearched
        });
      }
    }
  }

  render() {
    const {
      myPermissions,
      myPermissionsIsFetching,
      fetching
    } = this.props;
    let { draft, expand, sortBy, searchValue, draftSearched } = this.state;
    draft = draft ? draft : [];

    if (sortBy === "newest") {
      draft.sort((a, b) => moment(b.created_at) - moment(a.created_at));
    } else if (sortBy !== "newest") {
      draft.sort((a, b) => moment(a.created_at) - moment(b.created_at));
    }

    const meta = {
      title: "Drafts - URC Flour Division",
      description:
        "URC Flour Division seeks global awareness for its brands, enter new territories and explore untapped business opportunities. Learn more about us today!",
      canonical: window.location.href,
      meta: {
        charset: "utf-8",
        name: {
          keywords: "Drafts"
        }
      }
    };

    if (myPermissionsIsFetching) {
      return (
        <DocumentMeta {...meta}>
          <Layout>
            <Spin spinning={myPermissionsIsFetching}>
              <Skeleton active />
            </Spin>
          </Layout>
        </DocumentMeta>
      )
    }

    return (
      <DocumentMeta {...meta}>
        <Layout>
          {myPermissions.some(r => r.name === 'distributor.orders.view') ? <Layout.Content className="product-order-content">


            <Row gutter={48}>
              <Col className="product-order-form" span={24}>
                {/* HEADER */}
                <MediaQuery minWidth={1224}>
                  <div className="product-order-form-header">
                    <h2>Saved Drafts</h2>
                    <span>SORT BY</span>
                    <Select
                      style={{ width: 150 }}
                      placeholder="Date"
                      defaultValue={[sortBy]}
                      onChange={value => this.handleSortBy(value)}
                    >
                      <Option value="newest">Newest</Option>
                      <Option value="oldest">Oldest</Option>
                    </Select>
                  </div>
                </MediaQuery>
                <MediaQuery maxWidth={1224}>
                  <Row
                    type="flex"
                    justify="space-between"
                    align="middle"
                    className="sub-header"
                  >
                    <Col>
                      <h2>Saved Drafts</h2>
                    </Col>
                    <Col>
                      <a
                        onClick={() =>
                          this.handleXSSortBy(
                            this.state.XSsorter ? "newest" : "oldest"
                          )
                        }
                      >
                        <img src={swap} alt="" />
                      </a>
                    </Col>
                  </Row>
                </MediaQuery>
                <div className="draft-search-holder search-container">
                  <Input.Search
                    placeholder="Search"
                    prefix={<Icon type="search" />}
                    value={this.state.searchValue}
                    onChange={this.handleSearch}
                    size="large"
                  />
                </div>
                {this.searchResultIndicator()}
                <div className="product-order-result">
                  {draft && draft.length > 0 ? (
                    (searchValue.length >= 1 ? draftSearched : draft).map(
                      (order, index) => {
                        let product = order.draft_order_material || [];
                        let cbm, price, quantity;
                        if (product.length > 0) {
                          price = _.chain(product)
                            .map(
                              product =>
                                (product.material_detail.price + (product.material_detail.price * 0.12)) * product.quantity
                            )
                            .sum()
                            .value();
                          quantity = _.chain(product)
                            .map(product => parseInt(product.quantity))
                            .sum()
                            .value();
                        }
                        return (
                          <Row type="flex" key={index} className="wrapper">
                            <Col
                              span={24}
                              className={
                                expand.indexOf(order.id) > -1
                                  ? ""
                                  : "wrapper-child-conditional"
                              }
                            >
                              <Row
                                type="flex"
                                justify="space-between"
                                align="middle"
                              >
                                <Col span={14}>
                                  <Row type="flex" align="middle">
                                    <Col>
                                      <span className="order-date">
                                        {moment(order.created_at).format(
                                          "MMMM DD, YYYY"
                                        )}
                                      </span>
                                    </Col>
                                    <span className="separator">&#9679;</span>
                                    <Col>
                                      <span className="product-order-length">
                                        {order.draft_order_material.length} Product/s on
                                        Draft
                                      </span>
                                    </Col>
                                  </Row>
                                </Col>
                                <Col span={10} align="right">
                                  <Button
                                    disabled={!myPermissions.some(r => r.name === 'distributor.orders.edit')}
                                    type="link"
                                    onClick={() =>
                                      this.props.history.push(
                                        `order?draftId=${order.id}`
                                      )
                                    }
                                  >
                                    <b>
                                      <Icon type="edit" />
                                      &nbsp; EDIT DRAFT
                                    </b>
                                  </Button>
                                </Col>
                              </Row>
                            </Col>

                            <Col span={24} style={{ overflowX: "auto" }}>
                              <List
                                bordered
                                itemLayout="horizontal"
                                dataSource={product}
                                renderItem={(row, index) => {
                                  return (
                                    <List.Item
                                      className="product"
                                      style={{
                                        display:
                                          expand.indexOf(order.id) > -1
                                            ? "block"
                                            : "none"
                                      }}
                                    >
                                      <MediaQuery minWidth={1224}>
                                        <Row
                                          gutter={24}
                                          type="flex"
                                          align="middle"
                                        >
                                          <Col span={3}>
                                            <span>{row.material_detail.material_number}</span>
                                          </Col>
                                          <Col span={3}>
                                            <span></span>
                                          </Col>
                                          <Col span={6}>
                                            <div className="product-information">
                                              <span className="product-id">
                                                { row.material_detail.description }
                                              </span>
                                            </div>
                                          </Col>
                                          <Col span={4}>
                                            <Col span={12} align="center">
                                              {numFormat("dot/comma", row.material_detail.price + (row.material_detail.price * 0.12)) || "0.00"}
                                            </Col>
                                            <Col span={12} align="center">
                                              {numFormat("dot/comma", row.material_detail.price) || "0.00"}
                                            </Col>
                                          </Col>
                                          <Col span={2} align="right">
                                            {row.quantity.toLocaleString()}
                                          </Col>
                                          <Col span={4} align="right">
                                            {numFormat("dot/comma", (row.material_detail.price + (row.material_detail.price * 0.12))* row.quantity) || "0.00"}
                                          </Col>
                                        </Row>
                                      </MediaQuery>
                                      <MediaQuery maxWidth={1224}>
                                        <Card className="card-info">
                                          <Row
                                            type="flex"
                                            justify="start"
                                            align="middle"
                                          >
                                            <Col span={12}>
                                              <small>Line #</small>
                                              <br />
                                              <span>{index + 1}</span>
                                            </Col>
                                            <Col span={11} offset={1}>
                                              <small>SKU Code</small> <br />
                                              <span></span>
                                            </Col>
                                            <Col span={24}>
                                              <small>SKU DESCRIPTION</small>
                                              <br />
                                              <span>

                                              </span>
                                            </Col>
                                            <Col span={12}>
                                              <small>Packing Scheme</small>
                                              <br />
                                              <span>

                                              </span>
                                            </Col>
                                            <Col span={11} offset={1}>
                                              <small>AMOUNT (IN USD)</small>
                                              <br />
                                              $&nbsp;
                                              <span>
                                                
                                              </span>
                                            </Col>
                                            <Col span={12}>
                                              <small>
                                                ORDERED QTY. (IN CS)
                                              </small>
                                              <br />
                                              <span>
                                                
                                              </span>
                                            </Col>
                                            <Col span={11} offset={1}>
                                              <small>CBM</small> <br />
                                              <span>
                                                
                                              </span>
                                            </Col>
                                          </Row>
                                        </Card>
                                      </MediaQuery>
                                    </List.Item>
                                  );
                                }}
                                header={
                                  <Row gutter={24}>
                                    <Col span={9}>
                                      <h3>Product</h3>
                                    </Col>
                                    <Col span={3}>
                                      <h3></h3>
                                    </Col>
                                    <Col span={4}>
                                      <Row>
                                        <Col span={24} align="center">
                                          AMOUNT  (PHP/UNIT)
                                        </Col>
                                      </Row>
                                      <Row>
                                        <Col span={12} align="center">
                                          VAT-IN
                                        </Col>
                                        <Col span={12} align="center">
                                          VAT-EX
                                        </Col>
                                      </Row>
                                    </Col>
                                    <Col span={3}>
                                      <h3>ORDERED QUANTITY</h3>
                                    </Col>
                                    <Col span={3} align="right">
                                      <h3>ORDERED AMOUNT (PHP)</h3>
                                    </Col>
                                    <Col
                                      span={2}
                                      style={{ textAlign: "right" }}
                                    >
                                      {myPermissions.some(r => r.name === 'distributor.orders.cancel')?(
                                        <Popconfirm
                                          title="Are you sure to delete this draft?"
                                          onConfirm={() =>
                                            this.handleCancelDraft(
                                              product[0]
                                                .customer_draft_order_id
                                            )}
                                          okText="Yes"
                                          cancelText="No"
                                          placement="topRight"
                                          okButtonProps={{ type: 'danger', ghost: true }}
                                        >
                                          <Icon className="delete-draft-button" type="delete" theme="filled" />
                                        </Popconfirm>
                                       
                                      ):''}
                                      
                                    </Col>
                                  </Row>
                                }
                                footer={
                                  <>
                                    <MediaQuery minWidth={1224}>
                                      <Card
                                        style={{
                                          padding: "30px 20px",
                                          borderTop: "none",
                                          borderRight: "none",
                                          borderLeft: "none"
                                        }}
                                      >
                                        <Row
                                          type="flex"
                                          justify="space-between"
                                          align="middle"
                                          className="order-draft-footer"
                                        >
                                          <Col xs={24} md={12}>
                                            {expand.indexOf(order.id) > -1 ? (
                                              <span
                                                className="toggle-button"
                                                onClick={() =>
                                                  this.handleToggleDetails(
                                                    order.id,
                                                    false
                                                  )
                                                }
                                              >
                                                Shorten Order Details ^
                                              </span>
                                            ) : (
                                                <span
                                                  className="toggle-button"
                                                  onClick={() =>
                                                    this.handleToggleDetails(
                                                      order.id,
                                                      true
                                                    )
                                                  }
                                                >
                                                  Expand Order Details
                                                </span>
                                              )}
                                          </Col>
                                          <Col xs={24} md={12}>
                                            <Row
                                              type="flex"
                                              justify="space-between"
                                              align="middle"
                                              className="draft-footer"
                                              gutter={16}
                                            >
                                              <Col md={8} lg={8} align="center">
                                                
                                              </Col>
                                              <Col md={7} lg={7} align="center">
                                                <label>TOTAL QUANTITY:</label>
                                                &nbsp;
                                                <span>{quantity}</span>
                                              </Col>
                                              <Col md={9} lg={9} align="center">
                                                <label>TOTAL AMOUNT: </label>
                                                <span>
                                                  PHP {numberFormat(price)}
                                                </span>
                                              </Col>
                                            </Row>
                                          </Col>
                                        </Row>
                                      </Card>
                                    </MediaQuery>
                                    <MediaQuery maxWidth={1224}>
                                      <Row
                                        type="flex"
                                        justify="space-between"
                                        align="middle"
                                        className={
                                          expand.indexOf(order.id) > -1
                                            ? "row-expand expand-border-top"
                                            : "row-expand "
                                        }
                                      >
                                        <Col span={2}>
                                          {expand.indexOf(order.id) > -1 ? (
                                            <span
                                              className="toggle-button"
                                              onClick={() =>
                                                this.handleToggleDetails(
                                                  order.id,
                                                  false
                                                )
                                              }
                                            >
                                              <img src={expandIcon} alt="" />
                                            </span>
                                          ) : (
                                              <span
                                                className="toggle-button"
                                                onClick={() =>
                                                  this.handleToggleDetails(
                                                    order.id,
                                                    true
                                                  )
                                                }
                                              >
                                                <img src={expandIcon} alt="" />
                                              </span>
                                            )}
                                        </Col>
                                        <Col span={22}>
                                          <Row
                                            type="flex"
                                            justify="space-between"
                                            align="middle"
                                          >
                                            <Col span={10}>
                                              <label>TOTAL AMOUNT:</label>
                                              <br />
                                              <span>
                                                $&nbsp;{numberFormat(price)}
                                              </span>
                                            </Col>
                                            <Col span={7}>
                                              <label>TOTAL QTY:</label>
                                              <br />
                                              <span>
                                                {quantity &&
                                                  quantity.toLocaleString()}
                                              </span>
                                            </Col>
                                            <Col span={7}>
                                              <label>TOTAL CBM:</label>
                                              <br />
                                              <span>{numberFormat(cbm)}</span>
                                            </Col>
                                          </Row>
                                        </Col>
                                      </Row>
                                    </MediaQuery>
                                  </>
                                }
                              />
                            </Col>
                          </Row>
                        );
                      }
                    )
                  ) : (
                      <Row
                        type="flex"
                        align="middle"
                        justify="center"
                        style={{ marginTop: "50px" }}
                      >
                        <Col>
                          {fetching ? <Spin /> : <h1>No Saved Drafts</h1>}
                        </Col>
                      </Row>
                    )}
                </div>
              </Col>
            </Row>
          </Layout.Content>
            : <Result
              status="warning"
              title="You have no permission to view this page."
            />
          }
        </Layout>
      </DocumentMeta>
    );
  }
}

const mapStateToProps = state => {
  const {
    permissions,
    drafts
  } = state;

  return {
    myPermissions: permissions.data,
    myPermissionsIsFetching: permissions.fetching,
    draftData: drafts,
    fetching: drafts.fetching
  };
};

export default connect(mapStateToProps, {
  fetchPermissions,
  fetchDrafts,
  cancelDraft
})(reduxForm({ form: "draftForm", enableReinitialize: true })(Drafts));
