import { saveAs } from 'file-saver';
import moment from 'moment'
import { PDFDocument, StandardFonts, rgb } from 'pdf-lib'
import ExcelJS from 'exceljs'
import Papa from 'papaparse'
import { numberFormat } from "../../utilities";
const wb = new ExcelJS.Workbook()

export const downloadFile = {

    async EXCEL(orderDataArray) {
        let worksheetArray = []

        orderDataArray.map(orderData => {

            const worksheet = wb.addWorksheet(`${orderData.id}`)

            // Width of  column
            worksheet.getColumn('A').width = 5
            worksheet.getColumn('B').width = 20
            worksheet.getColumn('C').width = 15
            worksheet.getColumn('D').width = 50
            worksheet.getColumn('E').width = 15
            worksheet.getColumn('F').width = 15
            // worksheet.getColumn('G').width = 15
            // worksheet.getColumn('H').width = 15
            // worksheet.getColumn('I').width = 15
            // worksheet.getColumn('J').width = 15
            // worksheet.getColumn('K').width = 15
            // worksheet.getColumn('L').width = 15

            //Header
            worksheet.mergeCells('A1:F1');
            worksheet.getCell('F1').value = 'CUSTOMER INFO SHEET';
            worksheet.getCell('F1').alignment = { horizontal: 'center' };
            worksheet.getCell('F1').font = { bold: true, horizontal: 'center' };

            //Distributor Name
            worksheet.mergeCells('A2:C2');
            worksheet.getCell('C2').value = 'SHIP TO:';
            worksheet.getCell('C2').font = { bold: true };

            //Distributor Name (Value)
            worksheet.mergeCells('D2:F2');
            worksheet.getCell('F2').value = `${orderData.ship_to.name}`;

            //Distributor Code
            worksheet.mergeCells('A3:C3');
            worksheet.getCell('C3').value = 'ADDRESS:';
            worksheet.getCell('C3').font = { bold: true };

            //Distributor Code (Value)
            worksheet.mergeCells('D3:F3');
            worksheet.getCell('F3').value = `${orderData.ship_to.address}`;

            //Office Address
            worksheet.mergeCells('A4:C4');
            worksheet.getCell('C4').value = 'DELIVERY DATE:';
            worksheet.getCell('C4').font = { bold: true };

            //Office Address (Value)
            worksheet.mergeCells('D4:F4');
            worksheet.getCell('F4').value = `${moment(orderData.delivery_details.estimated_delivery_date, 'YYYY-MM-DD').format('MMM D, YYYY')}`;

            //Office #
            worksheet.mergeCells('A5:C5');
            worksheet.getCell('C5').value = 'PAYMENT TERM';
            worksheet.getCell('C5').font = { bold: true };

            //Office # (Value)
            worksheet.mergeCells('D5:F5');
            worksheet.getCell('F5').value = `${orderData.payment_term}`;

            //Phone #
            worksheet.mergeCells('A6:C6');
            worksheet.getCell('C6').value = 'SOURCE';
            worksheet.getCell('C6').font = { bold: true };

            //Phone # (Value)
            worksheet.mergeCells('D6:F6');
            worksheet.getCell('F6').value = orderData.source.map((source) => {
                return source.description
              }).join(', ');


            //Email Address
            worksheet.mergeCells('A7:C7');
            worksheet.getCell('C7').value = 'TRANSACTION REFERENCE NUMBER';
            worksheet.getCell('C7').font = { bold: true };

            // //Email Address (Value)
            worksheet.mergeCells('D7:F7');
            worksheet.getCell('F7').value = `${orderData.sales_order_number}`;

            worksheet.getCell('B9').value = 'PRODUCT';
            worksheet.getCell('B9').font = { bold: true };

            worksheet.getCell('C9').value = 'SKU CODE';
            worksheet.getCell('C9').font = { bold: true };

            worksheet.getCell('D9').value = 'VAT-EX';
            worksheet.getCell('D9').font = { bold: true };

            worksheet.getCell('E9').value = 'VAT-IN';
            worksheet.getCell('E9').font = { bold: true };

            worksheet.getCell('F9').value = 'QUANTITY (IN KG)';
            worksheet.getCell('F9').font = { bold: true };

            worksheet.getCell('G9').value = 'ORDERED QUANTITY';
            worksheet.getCell('G9').font = { bold: true };

            worksheet.getCell('H9').value = 'ORDERED AMOUNT (PHP)';
            worksheet.getCell('H9').font = { bold: true };

            // worksheet.getCell('I16').value = 'PRICE';
            // worksheet.getCell('I16').font = { bold: true };

            const lastRow = 10
            orderData.order_material.map((material, i) => {
                worksheet.getCell((`A${lastRow + i}`).toString()).value = `${i + 1}`;
                worksheet.getCell((`B${lastRow + i}`).toString()).value = `${material.material_details.description}`;
                worksheet.getCell((`C${lastRow + i}`).toString()).value = `${material.material_details.material_number}`;
                worksheet.getCell((`D${lastRow + i}`).toString()).value = `${numberFormat(material.unit_price) || '0.00'}`;
                worksheet.getCell((`E${lastRow + i}`).toString()).value = `${numberFormat(material.unit_price * 0.12) || '0.00'}`;
                worksheet.getCell((`F${lastRow + i}`).toString()).value = `${material.quantity.toLocaleString()}`;
                worksheet.getCell((`G${lastRow + i}`).toString()).value = `${numberFormat(material.price) || '0.00'}`;
            })

            const totalQuantity = orderData.order_material.reduce((a, b) => +a + +b.quantity, 0);

            let totalRow = lastRow + orderData.order_material.length + 1
            // Total Field
            worksheet.getCell(`B${totalRow}`).value = 'TOTAL';
            worksheet.getCell(`B${totalRow}`).font = { bold: true };

            worksheet.getCell((`F${totalRow}`).toString()).value = `${totalQuantity.toLocaleString()}`;
            worksheet.getCell(`F${totalRow}`).font = { bold: true };

            worksheet.getCell((`G${totalRow}`).toString()).value = `${orderData.total_quantity.toLocaleString()}`;
            worksheet.getCell(`G${totalRow}`).font = { bold: true };

            worksheet.getCell((`H${totalRow}`).toString()).value = `${orderData.order_price.vat_sales.toLocaleString()}`;
            worksheet.getCell(`H${totalRow}`).font = { bold: true };

            
            //Less Discount Field
            totalRow++;
            worksheet.getCell(`B${totalRow}`).value = 'Less Discount';
            worksheet.getCell(`B${totalRow}`).font = { bold: true };

            worksheet.getCell((`H${totalRow}`).toString()).value = `${orderData.order_price.discount.toLocaleString()}`;
            worksheet.getCell(`H${totalRow}`).font = { bold: true };

            //Total Net of Discount Field
            totalRow++;
            worksheet.getCell(`B${totalRow}`).value = 'Total, Net of Discount';
            worksheet.getCell(`B${totalRow}`).font = { bold: true };

            worksheet.getCell((`H${totalRow}`).toString()).value = `${(orderData.order_price.vat_sales - orderData.order_price.discount).toLocaleString()}`;
            worksheet.getCell(`H${totalRow}`).font = { bold: true };

            //Add. VAT Field
            totalRow++;
            worksheet.getCell(`B${totalRow}`).value = 'Add. VAT';
            worksheet.getCell(`B${totalRow}`).font = { bold: true };

            worksheet.getCell((`H${totalRow}`).toString()).value = `${orderData.order_price.vat_amount.toLocaleString()}`;
            worksheet.getCell(`H${totalRow}`).font = { bold: true };

            //Total Amount
            totalRow++;
            worksheet.getCell(`B${totalRow}`).value = 'Total Amount';
            worksheet.getCell(`B${totalRow}`).font = { bold: true };

            worksheet.getCell((`H${totalRow}`).toString()).value = `${orderData.order_price.total_amount.toLocaleString()}`;
            worksheet.getCell(`H${totalRow}`).font = { bold: true };

            //Less EWT Field
            totalRow++;
            worksheet.getCell(`B${totalRow}`).value = 'Less: EWT (If Applicable)';
            worksheet.getCell(`B${totalRow}`).font = { bold: true };

            worksheet.getCell((`H${totalRow}`).toString()).value = `${orderData.order_price.ewt_amount.toLocaleString()}`;
            worksheet.getCell(`H${totalRow}`).font = { bold: true };
            //Amount for Payment
            totalRow++;
            worksheet.getCell(`B${totalRow}`).value = 'Amount for Payment';
            worksheet.getCell(`B${totalRow}`).font = { bold: true };

            worksheet.getCell((`H${totalRow}`).toString()).value = `${(orderData.order_price.total_amount - orderData.order_price.ewt_amount).toLocaleString()}`;
            worksheet.getCell(`H${totalRow}`).font = { bold: true };

            worksheetArray.push(worksheet)

        })

        const buf = await wb.xlsx.writeBuffer()
        saveAs(new Blob([buf]), 'Order Status Report.xlsx')

        worksheetArray.map((dataObj) => {
            wb.removeWorksheet(dataObj.id)
        })



    },

    CSV(orderData) {
        let csvArray = []
        orderData.map(data => {
            data.order_material.map(material => {
                const totalQuantity = data.order_material.reduce((a, b) => +a + +b.quantity, 0);
                const totalWeight = data.order_material.reduce((a, b) => +a + +b.weight, 0);
                
                csvArray.push({
                    'Purchase Order Number': data.order_transaction_id,
                    'Sales Order Number': data.sales_order_number,
                    'Deliver To': data.ship_to.name,
                    'Address': data.ship_to.address,
                    'Delivery Date': moment(data.delivery_details.estimated_delivery_date, 'YYYY-MM-DD').format('MMM D, YYYY'),
                    'Payment Term': data.payment_term,
                    'Source': data.source.map((source) => {
                        return source.description
                      }).join(', '),
                    'Material Number': material.material_details.material_number,
                    'Material Name': material.material_details.description,
                    'Amount (VAT-EX)': numberFormat(material.unit_price) || '0.00',
                    'Amount (VAT-IN)': numberFormat(material.unit_price + (material.unit_price * 0.12)) || '0.00',
                    'Quantity': material.weight.toLocaleString(),
                    'Ordered Quantity': material.quantity.toLocaleString(),
                    'Ordered Amount': numberFormat(material.price) || '0.00',
                    'Total Weight': totalWeight,
                    'Total Quantity': totalQuantity,
                    'Total Amount (VAT-EX)': data.order_price.vat_sales.toLocaleString(),
                    'Less: Discount': data.order_price.discount.toLocaleString(),
                    'Total, Net of Discount': (data.order_price.vat_sales - data.order_price.discount).toLocaleString(),
                    'Add: VAT': data.order_price.vat_amount.toLocaleString(),
                    'Total Amount': data.order_price.total_amount.toLocaleString(),
                    'Less: EWT (If Applicable)': data.order_price.ewt_amount.toLocaleString(),
                    'Amount for Payment': (data.order_price.total_amount - data.order_price.ewt_amount).toLocaleString()                 
                })
            });
        });

        const newCsv = Papa.unparse(csvArray)

        const blob = new Blob([newCsv], { type: "text/csv;charset=utf-8" });
        saveAs(blob, "Order Status Report.csv");

    },

    async PDF(orderDataArray) {
        const newArray = []
        orderDataArray.map(data => {
            data.order_material.map(material => {
                const totalQuantity = data.order_material.reduce((a, b) => +a + +b.quantity, 0);
                const totalWeight = data.order_material.reduce((a, b) => +a + +b.weight, 0);
                
                newArray.push({
                    'Purchase Order Number': data.order_transaction_id,
                    'Sales Order Number': data.sales_order_number,
                    'Deliver To': data.ship_to.name,
                    'Address': data.ship_to.address,
                    'Delivery Date': moment(data.delivery_details.estimated_delivery_date, 'YYYY-MM-DD').format('MMM D, YYYY'),
                    'Payment Term': data.payment_term,
                    'Source': data.source.map((source) => {
                        return source.description
                      }).join(', '),
                    'Material Number': material.material_details.material_number,
                    'Material Name': material.material_details.description,
                    'Amount (VAT-EX)': numberFormat(material.unit_price) || '0.00',
                    'Amount (VAT-IN)': numberFormat(material.unit_price * 0.12) || '0.00',
                    'Quantity': material.weight.toLocaleString(),
                    'Ordered Quantity': material.quantity.toLocaleString(),
                    'Ordered Amount': numberFormat(material.price) || '0.00',
                    'Total Weight': totalWeight,
                    'Total Quantity': totalQuantity,
                    'Total Amount (VAT-EX)': data.order_price.vat_sales.toLocaleString(),
                    'Less: Discount': data.order_price.discount.toLocaleString(),
                    'Total, Net of Discount': (data.order_price.vat_sales - data.order_price.discount).toLocaleString(),
                    'Add: VAT': data.order_price.vat_amount.toLocaleString(),
                    'Total Amount': data.order_price.total_amount.toLocaleString(),
                    'Less: EWT (If Applicable)': data.order_price.ewt_amount.toLocaleString(),
                    'Amount for Payment': (data.order_price.total_amount - data.order_price.ewt_amount).toLocaleString()                 
                })
            });
        });

        // Embed the Times Roman font
        const pdfDoc = await PDFDocument.create()
        const timesRomanFont = await pdfDoc.embedFont(StandardFonts.TimesRoman)

        // Split page by 80
        let pageArray = splitArrayIntoChunksOfLen(newArray, 45)

        let pageCount = 0
        pageArray.map((orderData, i) => {
            const page = pdfDoc.addPage([1750, 500])
            const { height } = page.getSize()
            page.moveTo(240, 120);

            page.drawText('#', { x: 20, y: height - 4 * 9, size: 9, font: timesRomanFont })
            page.drawText('PO Number', { x: 40, y: height - 4 * 9, size: 9, font: timesRomanFont })
            page.drawText('SO Number', { x: 90, y: height - 4 * 9, size: 9, font: timesRomanFont })
            page.drawText('Deliver To', { x: 155, y: height - 4 * 9, size: 9, font: timesRomanFont })
            page.drawText('Address', { x: 235, y: height - 4 * 9, size: 9, font: timesRomanFont })
            page.drawText('Delivery Date', { x: 300, y: height - 4 * 9, size: 9, font: timesRomanFont })
            page.drawText('Payment Term', { x: 370, y: height - 4 * 9, size: 9, font: timesRomanFont })
            page.drawText('Source', { x: 440, y: height - 4 * 9, size: 9, font: timesRomanFont })
            page.drawText('Material Number', { x: 490, y: height - 4 * 9, size: 9, font: timesRomanFont })
            page.drawText('Material Name', { x: 580, y: height - 4 * 9, size: 9, font: timesRomanFont })
            page.drawText('Amount (VAT-EX)', { x: 660, y: height - 4 * 9, size: 9, font: timesRomanFont })
            page.drawText('Amount (VAT-IN)', { x: 740, y: height - 4 * 9, size: 9, font: timesRomanFont })
            page.drawText('Quantity', { x: 820, y: height - 4 * 9, size: 9, font: timesRomanFont })
            page.drawText('Ordered Quantity', { x: 860, y: height - 4 * 9, size: 9, font: timesRomanFont })
            page.drawText('Ordered Amount', { x: 940, y: height - 4 * 9, size: 9, font: timesRomanFont })
            page.drawText('Total Weight', { x: 1010, y: height - 4 * 9, size: 9, font: timesRomanFont })
            page.drawText('Total Quantity', { x: 1070, y: height - 4 * 9, size: 9, font: timesRomanFont })
            page.drawText('Total Amount (VAT-EX)', { x: 1130, y: height - 4 * 9, size: 9, font: timesRomanFont })
            page.drawText('Less: Discount', { x: 1240, y: height - 4 * 9, size: 9, font: timesRomanFont })
            page.drawText('Total, Net of Discount', { x: 1310, y: height - 4 * 9, size: 9, font: timesRomanFont })
            page.drawText('Add: VAT', { x: 1400, y: height - 4 * 9, size: 9, font: timesRomanFont })
            page.drawText('Total Amount', { x: 1450, y: height - 4 * 9, size: 9, font: timesRomanFont })
            page.drawText('Less: EWT (If Applicable)', { x: 1520, y: height - 4 * 9, size: 9, font: timesRomanFont })
            page.drawText('Amount for Payment', { x: 1640, y: height - 4 * 9, size: 9, font: timesRomanFont })

            orderData.map((value, i) => {
                pageCount++
                page.drawText((pageCount).toString(), { x: 20, y: height - (6 + i) * 9, size: 8, font: timesRomanFont })
                page.drawText(value['Purchase Order Number'].toString(), { x: 40, y: height - (6 + i) * 9, size: 8, font: timesRomanFont })
                page.drawText(value['Sales Order Number'].toString(), { x: 90, y: height - (6 + i) * 9, size: 7, font: timesRomanFont })
                page.drawText(value['Deliver To'].toString(), { x: 155, y: height - (6 + i) * 9, size: 8, font: timesRomanFont })
                page.drawText(value['Address'].toString(), { x: 235, y: height - (6 + i) * 9, size: 8, font: timesRomanFont })
                page.drawText(value['Delivery Date'].toString(), { x: 300, y: height - (6 + i) * 9, size: 7, font: timesRomanFont })
                page.drawText(value['Payment Term'].toString(), { x: 370, y: height - (6 + i) * 9, size: 8, font: timesRomanFont })
                page.drawText(value['Source'].toString(), { x: 440, y: height - (6 + i) * 9, size: 8, font: timesRomanFont })
                page.drawText(value['Material Number'].toString(), { x: 490, y: height - (6 + i) * 9, size: 8, font: timesRomanFont })
                page.drawText(value['Material Name'].toString(), { x: 580, y: height - (6 + i) * 9, size: 8, font: timesRomanFont })
                page.drawText(value['Amount (VAT-EX)'].toString(), { x: 660, y: height - (6 + i) * 9, size: 8, font: timesRomanFont })
                page.drawText(value['Amount (VAT-IN)'].toString(), { x: 740, y: height - (6 + i) * 9, size: 8, font: timesRomanFont })
                page.drawText(value['Quantity'].toString(), { x: 820, y: height - (6 + i) * 9, size: 8, font: timesRomanFont })
                page.drawText(value['Ordered Quantity'].toString(), { x: 860, y: height - (6 + i) * 9, size: 8, font: timesRomanFont })
                page.drawText(value['Ordered Amount'].toString(), { x: 940, y: height - (6 + i) * 9, size: 8, font: timesRomanFont })
                page.drawText(value['Total Weight'].toString(), { x: 1010, y: height - (6 + i) * 9, size: 8, font: timesRomanFont })
                page.drawText(value['Total Quantity'].toString(), { x: 1070, y: height - (6 + i) * 9, size: 8, font: timesRomanFont })
                page.drawText(value['Total Amount (VAT-EX)'].toString(), { x: 1130, y: height - (6 + i) * 9, size: 8, font: timesRomanFont })
                page.drawText(value['Less: Discount'].toString(), { x: 1240, y: height - (6 + i) * 9, size: 8, font: timesRomanFont })
                page.drawText(value['Total, Net of Discount'].toString(), { x: 1310, y: height - (6 + i) * 9, size: 8, font: timesRomanFont })
                page.drawText(value['Add: VAT'].toString(), { x: 1400, y: height - (6 + i) * 9, size: 8, font: timesRomanFont })
                page.drawText(value['Total Amount'].toString(), { x: 1450, y: height - (6 + i) * 9, size: 8, font: timesRomanFont })
                page.drawText(value['Less: EWT (If Applicable)'].toString(), { x: 1520, y: height - (6 + i) * 9, size: 8, font: timesRomanFont })
                page.drawText(value['Amount for Payment'].toString(), { x: 1640, y: height - (6 + i) * 9, size: 8, font: timesRomanFont })
            })
        })
        const pdfBytes = await pdfDoc.save()
        const pdfBlob = new Blob([pdfBytes], { type: "application/pdf;charset=utf-8" });
        saveAs(pdfBlob, "Order Status Report.pdf");
    }

}

function splitArrayIntoChunksOfLen(arr, len) {
    let chunks = [], i = 0, n = arr.length;
    while (i < n) {
        chunks.push(arr.slice(i, i += len));
    }
    return chunks;
}