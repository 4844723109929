import React from 'react'
import { connect } from 'react-redux';
import { submitLogin } from '../../actions/login'
import { Form, Input, Button, Checkbox, Row, Col } from 'antd';
import { Link } from 'react-router-dom'

import './Login.scss';

const { Password } = Input;

class NormalLoginForm extends React.Component {
  handleSubmit = e => {
    const { onSubmitLogin } = this.props
    e.preventDefault();
    this.props.form.validateFields((err, values) => {
      if (!err) {
        onSubmitLogin(values)
      }
    });
  };

  componentWillReceiveProps(nextProps) {
    const { loggedIn, closeModal, form } = this.props
    if (loggedIn !== nextProps.loggedIn) {
      closeModal()
      form.resetFields();
    }
  }

  render() {
    let { closeModal, loggingIn, loginRejected, form: { getFieldDecorator }, errorMessage } = this.props
    const urlParams = new URLSearchParams(window.location.search);
    const emailParams = urlParams.get('email');

    return (
      <div className='login-container'>
        <div id='triangle-up' />
        {loginRejected && errorMessage === 'Unauthorized' && <span className='login-error-message'>Login Failed: Invalid Credential</span>}
        {loginRejected && errorMessage === 'Account Deactivated' && <span className='login-error-message'>Login Failed: Your Account has been deactivated!</span>}
        <Form onSubmit={this.handleSubmit}>
          <Form.Item label='USERNAME'>
            {getFieldDecorator('username', {
              initialValue: emailParams !== null ? emailParams : '',
              rules: [{ required: true, message: 'Please input your username!' }],
            })(
              <Input
                placeholder="Enter your Username"
                style={inputStyle}
              />
            )}
          </Form.Item>
          <Form.Item label='PASSWORD'>
            {getFieldDecorator('password', {
              rules: [{ required: true, message: 'Please input your Password!' }],
            })(
              <Password
                type="password"
                placeholder="Enter your Password"
              />
            )}
          </Form.Item>
          <Row align="center">
            <Col span={24} align="center">
              Read our <a href="https://www.urc.com.ph/privacy-statement" target="_blank">Privacy Statement</a>
            </Col>
          </Row>
          <br></br>
          <Form.Item>
            <Button
              loading={loggingIn}
              htmlType="submit"
              style={{ width: "100%", marginBottom: "10px" }}
              className="btn-small btn-primary"
            >
              Sign In
            </Button>
            <Row>
              <Col lg={12}>
                {getFieldDecorator('remember', {
                  valuePropName: 'checked',
                  initialValue: true,
                })(<Checkbox>Remember me</Checkbox>)}
              </Col>
              <Col lg={12} style={{ textAlign: 'right' }}>
                <Link to='/forgot-password' className="login-form-forgot" onClick={() => closeModal()}>Forgot password &gt;</Link>
              </Col>
            </Row>
          </Form.Item>
        </Form>
      </div>
    );
  }
}

const inputStyle = {
  border: '0',
  outline: '0',
  background: 'transparent',
  borderBottom: '2px solid #F0E6D8',
  width: ' 100%',
  height: '40px',
  boxShadow: '0 2px 2px -2px #F0E6D8',
  padding: 0
}

const WrappedNormalLoginForm = Form.create({ name: 'normal_login' })(NormalLoginForm);

function mapStateToProps(state) {
  return {
    loggingIn: state.login.loggingIn,
    loggedIn: state.login.loggedIn,
    loginRejected: state.login.loginRejected,
    errorMessage: state.login.errorMessage
  };
}

function mapDispatchToProps(dispatch) {
  return {
    onSubmitLogin: (data) => dispatch(submitLogin(data))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(WrappedNormalLoginForm);