import axios from "axios";

import config from "../config";
import { headers } from "../utilities";

export const CREATE_PAYMENT_ATTACHMENT = "CREATE_PAYMENT_ATTACHMENT";
export const CREATE_PAYMENT_ATTACHMENT_FULFILLED = "CREATE_PAYMENT_ATTACHMENT_FULFILLED";
export const CREATE_PAYMENT_ATTACHMENT_REJECTED = "CREATE_PAYMENT_ATTACHMENT_REJECTED";

export const uploadProofOfPayment = (fileList, data) => dispatch => {
	dispatch({
		type: CREATE_PAYMENT_ATTACHMENT,
		payload: {}
	});

	const formData = new FormData();

	fileList.forEach(file => {
		formData.append("files[]", file);
	});

	data.order_id.forEach(ids => {
		formData.append("order_id[]", ids);
	})

	formData.append("remarks", data.remarks)

	return axios
		.post(
			`${config.REACT_APP_API_BASE_URL}/order/payment`,
			formData,
			{ headers: { ...headers(), ...{ 'Content-Type': 'multipart/form-data' } } }
		)
		.then(response => {

			dispatch({
				type: CREATE_PAYMENT_ATTACHMENT_FULFILLED,
				payload: response.data
			});

			return { error: false, response: response.data };
		})

		.catch(err => {

			dispatch({
				type: CREATE_PAYMENT_ATTACHMENT_REJECTED,
				payload: err
			});

			return { error: true, response: err };
		});
}
