import * as _ from 'lodash';
import {
	DELETE_ORDER_ATTACHMENT, DELETE_ORDER_ATTACHMENT_FULFILLED, DELETE_ORDER_ATTACHMENT_REJECTED,
	CREATE_ORDER_ATTACHMENT, CREATE_PRODUCT_ORDER_FULFILLED, CREATE_ORDER_ATTACHMENT_REJECTED,
	DOWNLOAD_ATTACHMENT, DOWNLOAD_ATTACHMENT_FULFILLED, DOWNLOAD_ATTACHMENT_REJECTED
} from '../actions';

export default (state = {
	fetching: false,
	downloading: false,
	data: null,
	error: null
}, action) => {
	switch (action.type) {
		case DELETE_ORDER_ATTACHMENT:
			return { ...state, fetching: true, error: null }
		case DELETE_ORDER_ATTACHMENT_FULFILLED:
			return {
				...state, fetching: false, error: null, data: action.payload
			};
		case DELETE_ORDER_ATTACHMENT_REJECTED:
			return { ...state, fetching: false, error: action.payload, data: null }
		case CREATE_ORDER_ATTACHMENT:
			return { ...state, fetching: true, error: null }
		case CREATE_PRODUCT_ORDER_FULFILLED:
			return {
				...state, fetching: false, error: null, data: action.payload
			};
		case CREATE_ORDER_ATTACHMENT_REJECTED:
			return { ...state, fetching: false, error: action.payload, data: null }
		case DOWNLOAD_ATTACHMENT:
			return { ...state, downloading: true }
		case DOWNLOAD_ATTACHMENT_FULFILLED:
			return {
				...state, downloading: false
			};
		case DOWNLOAD_ATTACHMENT_REJECTED:
			return { ...state, downloading: false }
		default:
			return state;
	}
}
