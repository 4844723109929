import _ from "lodash";

import React, { Component } from 'react';
import { Modal, Icon, Tabs, Popconfirm, Button } from 'antd'

import AttachmentList from './AttachmentList';
import AttachmentUpload from './AttachmentUpload';

const { TabPane } = Tabs;

class ModalAttachment extends Component {

	render() {
		const {
			activeKey,
			excluded,
			onSubmitCallback,
			selectedOrder,
			onRemove,
			onReload,
			onDownload,
			onPreview
		} = this.props

		const tabs = {
			list: {
				title: "Attachment List",
				icon: "unordered-list",
				component: <AttachmentList selectedOrder={selectedOrder} onRemove={onRemove} onReload={onReload} onDownload={onDownload} onPreview={onPreview} />
			},
			upload: {
				title: "Attachment Upload",
				icon: "upload",
				component: <AttachmentUpload selectedOrder={selectedOrder} onSubmitCallback={onSubmitCallback} />
			}
		}

		return (<Modal
			title={<span>Purchase Order Document Attachment</span>}
			visible={this.props.isVisible}
			footer={null}
			maskClosable={true}
			closable={true}
			closeIcon={(<div>
				<Popconfirm
					title="Are you sure you want to proceed with the attachment?"
					onConfirm={() => {
						this.props.onClose(false)
					}}
					okText="Confirm"
					cancelText="Cancel"
				>
					<Icon type="close" />
				</Popconfirm>
			</div>)}
		>
			<Tabs defaultActiveKey={activeKey || "list"}>
				{
					_.map(_.omit(tabs, excluded), (value, index) => {
						return (<TabPane
							tab={
								<span>
									<Icon type={value.icon} />
									{value.title}
								</span>
							}
							key={index}
						>
							{value.component}
						</TabPane>)
					})
				}
			</Tabs>
		</Modal>)
	}
}

export default ModalAttachment
