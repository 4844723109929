import * as _ from 'lodash';
import {
	FETCH_ORDER_STATUS,
	FETCH_ORDER_STATUS_FULFILLED,
	FETCH_ORDER_STATUS_REJECTED,
	POST_PO_REFERENCE,
	POST_PO_REFERENCE_FULFILLED,
	POST_PO_REFERENCE_REJECTED,
	FETCH_STORAGE_LOCATION,
	GET_STORAGE_LOCATION_FULFILLED,
	GET_STORAGE_LOCATION_REJECTED
} from '../actions';

export default (state = {
	data: null,
	fetching: false,
	fetched: false,
	postPoLoading: false,
	error: null,
	storageLocationData: []
}, action) => {
	switch (action.type) {
		case FETCH_ORDER_STATUS:
			return { ...state, fetching: true, fetched: false, error: null }
		case FETCH_ORDER_STATUS_FULFILLED:
			return {
				...state, fetching: false, fetched: true, error: null,
				data: action.payload
			};
		case FETCH_ORDER_STATUS_REJECTED:
			return { ...state, fetching: false, fetched: false, error: action.payload, data: null };
		case FETCH_STORAGE_LOCATION:
			return { ...state, fetching: true, fetched: false, error: null }
		case GET_STORAGE_LOCATION_FULFILLED:
			return {
				...state, fetching: false, fetched: true, error: null,
				storageLocationData: action.payload
			};
		case GET_STORAGE_LOCATION_REJECTED:
			return { ...state, fetching: false, fetched: false, error: action.payload, data: null };
		case POST_PO_REFERENCE:
			return { ...state, postPoLoading: true }
		case POST_PO_REFERENCE_FULFILLED:
			return { ...state, postPoLoading: false, };
		case POST_PO_REFERENCE_REJECTED:
			return { ...state, postPoLoading: false };
		default:
			return state;
	}
}
