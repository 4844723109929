import * as _ from 'lodash';
import {
	SUBMIT_ORDER, SUBMIT_ORDER_FULFILLED, SUBMIT_ORDER_REJECTED,
	FETCH_ORDER_HISTORY, FETCH_ORDER_HISTORY_FULFILLED, FETCH_ORDER_HISTORY_REJECTED,
	SUBMIT_CUSTOMER_MATERIAL_REJECTED, SUBMIT_CUSTOMER_MATERIAL_FULFILLED, SUBMIT_CUSTOMER_MATERIAL
} from '../actions';

export default (state = {
	fetching: false,
	fetched: false,
	submitting: false,
	submitted: false,
	error: null
}, action) => {
	switch (action.type) {
		case SUBMIT_ORDER:
			return { ...state, submitting: true, submitted: false, error: null }
		case SUBMIT_ORDER_FULFILLED:
			return {
				...state, submitting: false, submitted: true, error: null,
				pricelistData: action.payload,
				// data: Object.assign(state.data || {}, _.mapKeys(action.payload, 'id'))
				data: action.payload

			};
		case SUBMIT_ORDER_REJECTED:
			return { ...state, submitting: false, submitted: false, error: action.payload, data: null };

		case FETCH_ORDER_HISTORY:
			return { ...state, submitting: true, submitted: false, error: null }
		case FETCH_ORDER_HISTORY_FULFILLED:
			return {
				...state, submitting: false, submitted: true, error: null,
				pricelistData: action.payload,
				// data: Object.assign(state.data || {}, _.mapKeys(action.payload, 'id'))
				data: action.payload

			};
		case FETCH_ORDER_HISTORY_REJECTED:
			return { ...state, submitting: false, submitted: false, error: action.payload, data: null };
		case SUBMIT_CUSTOMER_MATERIAL:
			return { ...state, submitting: true, submitted: false, error: null }
		case SUBMIT_CUSTOMER_MATERIAL_FULFILLED:
			return { ...state, submitting: false, submitted: true, error: null };
		case SUBMIT_CUSTOMER_MATERIAL_REJECTED:
			return { ...state, submitting: false, submitted: false, error: action.payload, data: null };
		default:
			return state;
	}
}
