import axios from 'axios';
import config from '../config';
import { headers } from '../utilities'

export const FETCH_SOLD_TO = 'FETCH_SOLD_TO';
export const FETCH_SOLD_TO_FULFILLED = 'FETCH_SOLD_TO_FULFILLED';
export const FETCH_SOLD_TO_REJECTED = 'FETCH_SOLD_TO_REJECTED';

export const fetchSoldTo = (data) => dispatch => {
  dispatch({
    type: FETCH_SOLD_TO,
    payload: []
  });
  return axios.get(`${config.REACT_APP_API_BASE_URL}/customer-sold-to-list`, { headers: headers() })
    .then(response => {
      dispatch({
        type: FETCH_SOLD_TO_FULFILLED,
        payload: response.data
      });
      return { error: false, response };
    })
    .catch(err => {
      dispatch({
        type: FETCH_SOLD_TO_REJECTED,
        payload: err
      })
      return { error: true, err };
    });
}