import React, { Component } from 'react';
import { Table, Popover, Icon, Popconfirm, message } from 'antd'

import PreviewFile from './PreviewFile';
import * as _ from "lodash";

class AttachmentList extends Component {

	constructor(props) {
		super(props);

		this.state = {
			selectedAttachment: null,
			previewModal: false
		}
	}

	confirmDelete = (id) => {
		const {
			selectedOrder,
			onRemove,
			onReload,
		} = this.props;

		onRemove(selectedOrder.id, id).then((response) => {
			if (response.error) {
				return message.error('Unable to remove attachment');
			}

			message.success('Successfully removed attachment');
			onReload()
		})
	}

	downloadAttachment = (id) => {
		const {
			selectedOrder,
			onDownload
		} = this.props;

		const attachment = _.find(selectedOrder?.attachment, { id: id })

		onDownload(selectedOrder.id, attachment.id).then((response) => {

			if (response.error) {
				return message.error("Unable to download file")
			}

			const link = document.createElement('a');
			link.href = window.URL.createObjectURL(new Blob([response.response]));
			link.download = attachment.filename
			link.click();
		})
	}

	previewAttachment = (id) => {
		const {
			selectedOrder,
			onPreview
		} = this.props;

		let attachment = _.find(selectedOrder?.attachment, { id: id });

		onPreview(selectedOrder.id, id).then((response) => {
			attachment = { ...attachment, ...{ file_url: response.response.data.file } };

			this.setState({
				selectedAttachment: attachment,
				previewModal: true
			})
		})
	}

	onClosePreview = () => {
		this.setState({
			previewModal: false
		})
	}

	render() {
		const {
			selectedAttachment,
			previewModal
		} = this.state
		const {
			selectedOrder
		} = this.props;

		const columns = [
			{
				title: "Filename",
				dataIndex: "filename",
				key: "filename"
			},
			{
				title: "File Size",
				dataIndex: "size",
				key: "size",
			},
			{
				width: 120,
				title: "Actions",
				dataIndex: "id",
				key: "",
				render: (id, row) => {
					return (<div>
						<Popover content={(<p>Delete</p>)}>
							<Popconfirm
								title="Are you sure delete this attachment?"
								onConfirm={() => this.confirmDelete(id)}
								okText="Confirm"
								cancelText="Cancel"
							>
								<span style={{ cursor: 'pointer', margin: 5 }}>
									<Icon type="delete" style={{ fontSize: '1em', color: "#A57271" }} />
								</span>
							</Popconfirm>
						</Popover>
						<Popover content={(<p>Download</p>)}>
							<span style={{ cursor: 'pointer', margin: 5 }} onClick={() => this.downloadAttachment(id)}>
								<Icon type="download" style={{ fontSize: '1em', color: "#A57271" }} />
							</span>
						</Popover>
						<Popover content={(<p>View File</p>)}>
							<span style={{ cursor: 'pointer', margin: 5 }} onClick={() => this.previewAttachment(id)}>
								<Icon type="file-search" style={{ fontSize: '1em', color: "#A57271" }} />
							</span>
						</Popover>
					</div>)
				}
			},
		];
		return (<div>
			<Table
				columns={columns}
				dataSource={selectedOrder?.attachment || []}
			/>
			<PreviewFile isVisible={previewModal} file={selectedAttachment} onClose={this.onClosePreview} />
		</div>)
	}
}

export default AttachmentList
