import axios from "axios";

import config from "../config";
import { headers } from "../utilities";
import { serialize } from '../utilities/helper'

export const FETCH_ORDER_DETAILS = "FETCH_ORDER_DETAILS";
export const FETCH_ORDER_DETAILS_FULFILLED = "FETCH_ORDER_DETAILS_FULFILLED";
export const FETCH_ORDER_DETAILS_REJECTED = "FETCH_ORDER_DETAILS_REJECTED";

export const fetchOrderDetails = (data) => dispatch => {
  const newUrl = `${config.REACT_APP_API_BASE_URL}/order/${data}`;

  dispatch({
    type: FETCH_ORDER_DETAILS,
    payload: {}
  });
  return axios
    .get(newUrl, { headers: headers() })
    .then(response => {
      let responseData = response.data;
      dispatch({
        type: FETCH_ORDER_DETAILS_FULFILLED,
        payload: responseData
      });
      // return response;
      return { error: false, response: responseData, type: FETCH_ORDER_DETAILS_FULFILLED };
    })
    .catch(err => {
      dispatch({
        type: FETCH_ORDER_DETAILS_REJECTED,
        payload: err
      });
      return { error: true, err };
    });
};

export const fetchOrderById = (data) => dispatch => {
  const newUrl = `${config.REACT_APP_API_BASE_URL}/order-detail/${data}`;

  dispatch({
    type: FETCH_ORDER_DETAILS,
    payload: {}
  });
  return axios
    .get(newUrl, { headers: headers() })
    .then(response => {
      let responseData = response.data;
      dispatch({
        type: FETCH_ORDER_DETAILS_FULFILLED,
        payload: responseData
      });
      // return response;
      return { error: false, response: responseData, type: FETCH_ORDER_DETAILS_FULFILLED };
    })
    .catch(err => {
      dispatch({
        type: FETCH_ORDER_DETAILS_REJECTED,
        payload: err
      });
      return { error: true, err };
    });
};
