import React, { Component } from 'react'
import Slider from 'react-slick'
import { Row, Col } from 'antd'

import HardFlour from '../../assets/images/categories/Hard Flour.png'
import SoftFlour from '../../assets/images/categories/Soft Flour.png'
import SpecialtyFlour from '../../assets/images/categories/Specialty Flour.png'

import { connect } from 'react-redux'
import { redirectFilter } from '../../actions/home'
import { fetchFilterOptions } from '../../actions/filterOptions'

function NextBtnStyle(props) {
  const { onClick } = props
  return (
    <div className='react-slick-custom-btn-right' onClick={onClick}>
      <i className='right' />
    </div>
  )
}

function PrevBtnStyle(props) {
  const { onClick } = props
  return (
    <div className='react-slick-custom-btn-left' onClick={onClick}>
      <i className='left' />
    </div>
  )
}

class SellProduct extends Component {
  constructor() {
    super()
    this.state = { isDragging: false };
  }


  midDrag = false;

  toggleMidDrag = () => {
    this.midDrag = !this.midDrag;
  }

  componentDidMount() {
    this.props.fetchFilterOptions()
  }

  render() {
    const sellData = [
      {
        title: 'HARD FLOUR',
        description: 'Our variety of hard flour options are recommended for pan de sal, buns, rolls and other bread varieties intended to have short bite because of its high protein content.',
        imageLink: HardFlour
      },
      {
        title: 'SOFT FLOUR',
        description: 'Milled from soft wheat, our soft flour options are best in making cookies, pastries, wafers, and are also good for blending with hard flour.',
        imageLink: SoftFlour
      },
      {
        title: 'SPECIALTY FLOUR',
        description: 'We offer flour blends for special purposes, each best used for production of popular and fundamental elements to Filipino food like noodles, ensaymada, and cakes.',
        imageLink: SpecialtyFlour
      },
    ]

    let dragging = false;
    const settingsList = {
      beforeChange: () => dragging = true,
      afterChange: () => dragging = false,
      dots: false,
      infinite: true,
      slidesToShow: 3,
      slidesToScroll: 1,
      speed: 500,
      cssEase: 'linear',
      nextArrow: <NextBtnStyle />,
      prevArrow: <PrevBtnStyle />,
      autoplay: true,
      responsive: [
        {
          breakpoint: 1370,
          settings: {
            slidesToShow: 3,
            slidesToScroll: 3,
            centerPadding: '10px',
            infinite: true
          }
        },
        {
          breakpoint: 1100,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 2,
            initialSlide: 2
          }
        },
        {
          breakpoint: 820,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
          }
        }
      ]
    }
    return (
      <Row>
        <Col>
          <div className='sell-product-container'>
            <div className='sell-header-title'>
              <center>
                <h1>What We Sell</h1>
              </center>
            </div>
            <div className='sell-item-product-slider'>
              <Slider {...settingsList}>
                {
                  sellData.map((data, i) => {
                    return (
                      <div className='sell-item-container animated fadeIn' key={i}>
                        <div className='sell-item-card'>
                            <div className='image-container'>
                              <img src={data.imageLink} alt='product-item' />
                            </div>
                            <div className='sell-item-description'>
                              <span className='sell-item-title'>{data.title}</span>
                              <p className='sell-item-info'> {`${data.description}`} </p>
                            </div>
                          </div>
                      </div>
                    )
                  })
                }
              </Slider>
            </div>
          </div>
        </Col>
      </Row >
    )
  }
}

function mapStateToProps({ home }) {
  return home
}

export default connect(mapStateToProps, { redirectFilter, fetchFilterOptions })(SellProduct)