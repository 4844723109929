import axios from 'axios';
import config from '../config';
import { headers } from '../utilities'

export const FETCH_STORAGE_LOCATION = 'FETCH_STORAGE_LOCATION';
export const FETCH_STORAGE_LOCATION_FULFILLED = 'FETCH_STORAGE_LOCATION_FULFILLED';
export const FETCH_STORAGE_LOCATION_REJECTED = 'FETCH_STORAGE_LOCATION_REJECTED';

export const fetchStorageLocation = (data) => dispatch => {
  dispatch({
    type: FETCH_STORAGE_LOCATION,
    payload: []
  });
  return axios.get(`${config.REACT_APP_API_BASE_URL}/storage-location?is_paginated=false`, { headers: headers() })
    .then(response => {
      dispatch({
        type: FETCH_STORAGE_LOCATION_FULFILLED,
        payload: response.data
      });
      return { error: false, response };
    })
    .catch(err => {
      dispatch({
        type: FETCH_STORAGE_LOCATION_REJECTED,
        payload: err
      })
      return { error: true, err };
    });
}
