import _ from "lodash";

import React, { Component } from 'react';
import { Modal, Button } from 'antd'
import { Document, Page } from 'react-pdf/dist/entry.webpack';

class PreviewFile extends Component {

	constructor(props) {
		super(props)

		this.state = {
			totalPdfPages: 0,
			currentPage: 1
		}
	}

	onPdfLoad = (pdfData) => {
		this.setState({
			totalPdfPages: pdfData._pdfInfo.numPages
		})
	}

	goToPrevPage = () => {
		const { currentPage } = this.state
		this.setState({
			currentPage: Math.max(currentPage - 1, 1)
		});
	};

	goToNextPage = () => {
		const { currentPage, totalPdfPages } = this.state
		this.setState({
			currentPage: Math.min(currentPage + 1, totalPdfPages)
		});
	};

	render() {
		const {
			onClose,
			isVisible,
			file
		} = this.props

		const {
			currentPage,
			totalPdfPages
		} = this.state

		return (<Modal
			title={<span>View File {file?.filename}</span>}
			style={{ top: 20, display: "grid", justifyContent: "center" }}
			visible={isVisible}
			onCancel={onClose}
			maskClosable={true}
			closable={true}
			width={"60rem"}
			footer={file?.type === "application/pdf" ? [
				<Button variant="secondary" onClick={this.goToPrevPage} disabled={currentPage <= 1}>
					Previous
				</Button>,
				<Button variant="secondary" onClick={this.goToNextPage} disabled={currentPage >= totalPdfPages}>
					Next
				</Button>
			]: null}
		>
			{_.startsWith(file?.type, "image/") ? <img src={file?.file_url} alt={file?.filename} style={{ width: "100%" }} /> : ""}
			{file?.type === "application/pdf" ? <Document file={file?.file_url} onLoadSuccess={this.onPdfLoad} >
				<Page pageNumber={currentPage} />
			</Document> : ""}

		</Modal>)
	}
}

export default PreviewFile
