import React from 'react'
import './Home.scss'
import Banner from '../Home/banner.js'
import OurBrands from '../Home/ourBrands.js'
import SellProduct from '../Home/sellProduct.js'
import DocumentMeta from "react-document-meta";

export default function Home() {

  const meta = {
    title: "URC Flour Division",
    description: "URC Flour Division is tasked w/ the worldwide distribution of URC products. It serves nations & territories in N. America, Europe & more. Contact us today!",
    canonical: window.location.href,
    meta: {
      charset: "utf-8",
      name: {
        keywords: "URC Flour Division"
      }
    }
  };

  return (
    <DocumentMeta {...meta}>
      <div className='home-container'>
        <Banner />
        <OurBrands />
        <SellProduct />
      </div>
    </DocumentMeta>
  )
}

console.log(`Home Initialized`)
