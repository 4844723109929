import React from "react";
import { connect } from "react-redux";
import { Layout, Card, Modal, Row, Col, Button } from "antd";
import { withRouter } from "react-router-dom";
import { Field, reduxForm } from "redux-form";
import moment from 'moment';
import { _ } from "core-js";
import * as lodash from "lodash";
const validate = (values) => {
  const errors = {};
  if (!values.customer_address) {
    errors.customer_address = "Required";
  }
  if (!values.customer_sold_to) {
    errors.customer_sold_to = "Required";
  }
  if (!values.order_type) {
    errors.order_type = "Required";
  }
  if (!values.estimate_time_of_delivery) {
    errors.estimate_time_of_delivery = "Required";
  }



  if (!values.special_instructions && values.volume === 'others') {
    errors.special_instructions = "Required";
  }

  return errors;
};

class DeliveryDetails extends React.Component {
  constructor(props) {
    super(props);
    this.state = { selectedContainerOptionsValue: 0, orderType: null, deliveryDate: moment().toDate(), selectedShipToCustomer: null };
  }
  displayDistributorBasedOnType(type) {
    const { distributor } = this.props;
    let emailArray = [];
    distributor &&
      distributor.contact.map((data) => {
        if (data.type === type) emailArray.push(data);
      });
    return emailArray;
  }
  displayContactBasedOnId(id, column) {
    const { userInfo } = this.props;
    return userInfo.contact.find((contactData) => contactData.id === id)[
      column
    ];
  }

  handleContainerOptions = (e) => {
    this.setState({
      selectedContainerOptionsValue: e.target.value,
    });
  };

  selectOrderType = (e) => {
    this.setState({ orderType: e.target.value })
    const deliveryDate = e.target.value === 'delivery' ? moment().add(3, 'days').toDate() : moment().toDate();

    this.handleDateChange(deliveryDate)
    this.setState({ minDate: deliveryDate })
  }

  handleDateChange = (date) => {
    this.setState({ deliveryDate: date })
    this.props.change('estimate_time_of_delivery', date);
  }

  handleSpecialInstruction = (e) => {
    this.setState({ special_instruction: e.target.value })
    this.props.change('special_instruction', e.target.value);
  }

  handleSoldToSelect = async (e) => {
    await this.props.loadShipTo({ customer_code: e.target.value });

    const partner_code = this.props.shipTo?.length === 1 ? this.props.shipTo[0].partner_code : '';

    this.setState({ selectedSoldToCustomer: e.target.value, selectedShipToCustomer: partner_code })
    this.props.change('customer_address', partner_code);
    this.props.change('customer_ship_to', partner_code);
  }

  handleShipToSelect = (e) => {
    this.setState({ selectedShipToCustomer: e.target.value });
    this.props.change('customer_address', e.target.value);
  }

  render() {
    const {
      handleSubmit,
      isOrderDeliveryModalShow,
      onCloseDeliveryFormModal,
      renderSelectField,
      renderInput,
      renderDatepicker,
      distributor,
      soldTo,
      shipTo,
      isFetchingPriceList,
      defaultDeliveryDate
    } = this.props;

    const {
      selectedSoldToCustomer,
      selectedShipToCustomer
    } = this.state;

    const customerTagging = distributor.tagging || [];

    return (
      <Modal
        className="order-delivery-confirm-modal"
        visible={isOrderDeliveryModalShow}
        style={{ top: 20 }}
        onCancel={() => onCloseDeliveryFormModal()}
        footer={null}
        closable={false}
        width={750}
        maskClosable={false}
      >
        <Layout id="Clicked_Order_Submit">
          <Layout.Content className="order-delivery-header">
            <Row>
              <Col>
                <h1>Deliver Details</h1>
              </Col>
            </Row>
          </Layout.Content>
          <Layout.Content className="order-confirmation-content">
            <Card style={{ padding: "1vh 1vw" }} className="order-confirmation-content">
              <Layout.Content>
                <form onSubmit={handleSubmit}>
                  <Row className="delivery-details-form" gutter={24}>
                    <Col md={24}>
                      <div className="form-label">
                        <h3>Sold To</h3>
                        <Field
                          name={`customer_sold_to`}
                          placeholder="Click To Add Sold To"
                          className="inputStyle"
                          component={renderSelectField}
                          onChange={this.handleSoldToSelect}
                          defaultValue=""
                          required
                        >
                          <option value="" title="default" className="select">
                            Click To add Sold to
                          </option>
                          {
                            soldTo.map((data) => {
                              return (
                                <option
                                  value={data?.customer_partner_code || data.customer_code}
                                  title={data.customer?.name || data.name}
                                  className="select">
                                  {data.customer?.name || data.name} ({ data.customer.customer_code })
                                </option>
                              )
                            })
                          }
                        </Field>
                      </div>
                    </Col>
                    <Col md={24}>
                      <div className="form-label">
                        <h3>Ship To</h3>
                        <Field
                          name={`customer_ship_to`}
                          placeholder="Click To Add Ship To"
                          className="inputStyle"
                          component={renderSelectField}
                          onChange={this.handleShipToSelect}
                          defaultValue=""
                          value={selectedShipToCustomer}
                          required
                          disabled={!selectedSoldToCustomer}
                        >
                          <option value="" className="select">
                            Click To add Ship to
                          </option>
                          {
                            shipTo ? shipTo.map((data) => {
                              return (
                                <option
                                  value={data?.ship_to.customer_code || data.customer_code}
                                  title={data.ship_to?.name || data.name}
                                  className="select">
                                  {data.ship_to?.name || data.name} ({ data.ship_to?.customer_code })
                                </option>
                              )
                            }) : ''
                          }
                        </Field>
                      </div>
                    </Col>
                    <Col md={24}>
                      <div className="form-label">
                        <h3>Address</h3>
                        <Field
                          name={`customer_address`}
                          placeholder="Click To Add Ship To"
                          className="inputStyle"
                          component={renderSelectField}
                          required
                          disabled
                        >
                          <option value="" selected className="select">
                            Address
                          </option>
                          {
                            shipTo ? shipTo.map((data) => {
                              return (
                                <option
                                  value={data?.partner_code || data.customer_code}
                                  title={data.ship_to?.address || data.address}
                                  className="select">
                                  {(data.ship_to?.address || data.address) + ', ' + (data.ship_to?.city || data.city) + ', ' + (data.ship_to?.postal_code || data.postal_code)}
                                </option>
                              )
                            }) : ''
                          }
                        </Field>
                      </div>
                    </Col>
                    <Col md={12}>
                      <div className="form-label">
                        <h3>Type</h3>
                        <Field
                          name={`order_type`}
                          placeholder="Select Order Type"
                          className="inputStyle"
                          onChange={this.selectOrderType}
                          component={renderSelectField}
                          required
                        >
                          <option value="" selected className="select">
                            Select Order Type
                          </option>
                          <option value="delivery">Delivery</option>
                          <option value="pick-up">Pickup</option>
                        </Field>
                      </div>

                    </Col>

                    <Col md={12}>
                      <div className="form-label">
                        <h3>Delivery Date</h3>
                        {customerTagging.includes("mto") ? (<div className="form-label">{moment(defaultDeliveryDate).format("YYYY/MM/DD")}</div>) : (<div className="form-label">
                          <Field
                            name="estimate_time_of_delivery"
                            onChange={this.handleDateChange}
                            selected={this.state.deliveryDate}
                            minDate={this.state.minDate}
                            value={this.state.deliveryDate}
                            component={renderDatepicker}
                            label="Estimated Time of Delivery"
                            className="inputStyle"
                            autoComplete="off"
                            autoCorrect="off"
                            spellCheck="off"
                            disabled={this.state.orderType === 'pick-up'}
                          />
                        </div>)}
                      </div>
                    </Col>
                    {/* <Col md={24} style={{ fontStyle: 'italic', padding: '0px 24px' }}>Note: Delivery option is subject to URC Flour confirmation</Col>
                    <Col md={12}>
                      <div className="form-label">
                        <h3>Source</h3>
                        <Field
                          name={`sources`}
                          label="Type Sources of materials"
                          className="inputStyle"
                          component={renderInput}
                          defaultValue={sources}
                          readOnly={true}
                        />
                      </div>
                    </Col> */}

                    <Col md={24}>
                      <div className="form-label">
                        <h3>Special Instruction</h3>
                        <Field
                          name={`special_instruction`}
                          placeholder="Type Your Special Instructions Here"
                          onChange={this.handleSpecialInstruction}
                          component={
                            this.state.selectedContainerOptionsValue ===
                              "others"
                              ? renderInput
                              : "input"
                          }
                          className="inputStyle"
                        />
                      </div>
                    </Col>
                  </Row>
                  <Row>
                    <div className="btn-container">
                      <Button
                        loading={isFetchingPriceList}
                        htmlType="submit"
                        className="btn-small btn-primary"
                      >
                        {isFetchingPriceList
                          ? null
                          : "Proceed To Order Form"}

                      </Button>
                    </div>
                  </Row>
                </form>
              </Layout.Content>
            </Card>
          </Layout.Content>
        </Layout>
      </Modal>
    );
  }

  async componentDidMount() {
    const {
      draft
    } = this.props;

    if (!lodash.isEmpty(draft.deliveryDetailsDraftData)) {
      this.props.change('customer_sold_to', draft.deliveryDetailsDraftData.sold_to.customer_code);

      await this.handleSoldToSelect({
        target: {
          value: draft.deliveryDetailsDraftData.sold_to.customer_code
        }
      })

      this.props.change('customer_ship_to', draft.deliveryDetailsDraftData.ship_to.customer_code)
      this.handleShipToSelect({
        target: {
          value: draft.deliveryDetailsDraftData.ship_to.customer_code
        }
      })

      this.props.change('order_type', draft.deliveryDetailsDraftData.type)

      this.selectOrderType({
        target: {
          value: draft.deliveryDetailsDraftData.type
        }
      })
      this.handleDateChange(moment(draft.deliveryDetailsDraftData.estimated_delivery_date).toDate())
      this.handleSpecialInstruction({
        target: {
          value: draft.deliveryDetailsDraftData.special_instruction
        }
      })
    }
  }
}

function mapStateToProps(state, ownProps) {
  return {
    userInfo: state.distributor.data.distributor,
    deliveryDetailsForm: state.form,
    initialValues: {
      estimate_time_of_delivery: _.has(ownProps.draft.deliveryDetailsDraftData, 'delivery_details_form') ? ownProps.draft.deliveryDetailsDraftData.estimated_delivery_date : ownProps.defaultDeliveryDate,
      sources: ownProps.sources,
    },
    soldTo: ownProps.soldTo,
    shipTo: state.shipTo.data,
    fetchShipTo: ownProps.loadShipTo
  };
}

export default withRouter(
  connect(
    mapStateToProps
  )(
    reduxForm({
      form: "delivery_details_form",
      enableReinitialize: true,
      keepDirtyOnReinitialize: true,
      destroyOnUnmount: false,
      validate,
    })(DeliveryDetails)
  )
);
