import React, { useState, useEffect, Fragment } from "react";
import {
  Modal,
  Menu,
  Layout,
  Spin,
  Drawer,
  Button,
  Card,
  Icon,
  Dropdown,
  Badge,
  Select,
  Row
} from "antd";
import { Link } from "react-router-dom";
import Login from "../../Login";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import MediaQuery from "react-responsive";
import * as _ from "lodash";
import moment from "moment";
import config from "../../../config";
import axios from "axios";

import WarningIcon from "../../../assets/icons/warning-icon.png";
import OrderSubmittedIcon from "../../../assets/svg/check.svg";
import BrownIcon from "../../../assets/svg/brown.svg";

import {
  isLocalStorageEmpty,
  clearLocalStorage,
  headers,
} from "../../../utilities";
import "./Header.scss";
import "../../Login/Login.scss";


function Header(props) {
  const [setNotification] = useState([]);
  const [notifCount, setNotifCount] = useState(null);
  const [cloneNotification, setCloneNotification] = useState([]);
  const [filterBy, setFilterBy] = useState("");
  const [pageNum, setPageNum] = useState(1);
  const [notifVisible, setNotifVisible] = useState(false);
  const [distributorName, setDistributorName] = useState("")

  const { warning } = Modal

  const fetchMoreNotifications = async () => {

    try {
      const url = `${config.REACT_APP_API_BASE_URL}/notifications-distributor` + `?page=` + (pageNum) + `&limit=10`;
      const response = await axios.get(
        url,
        {
          headers: headers(),
        }

      );

      const pendingApproval = await response.data.data
        .filter(
          (row) =>
            row.notif_type === "sku replacement" && row.response === null
        );

      const other = await response.data.data
        .filter((row) => row.notif_type !== "sku replacement")

      const data = [...pendingApproval, ...other];
      setNotification(data);
      switch (filterBy) {
        case 1:
          return setCloneNotification(data);
        case 2:
          return setCloneNotification(
            data.filter((row) => {
              return (
                (row.notif_type === "order submitted") &&
                row.progress === "success"
              );
            })
          );
        case 3:
          return setCloneNotification(
            data.filter(
              (row) =>
                row.notif_type === "order confirmed" && row.progress === "success"
            )
          );
        default:
          return setCloneNotification(data);
      }
    } catch (err) {
    }
  };


  useEffect(() => {
    fetchMoreNotifications()
  }, [pageNum]);

  useEffect(() => {


    let isMounted = true;

    const fetchNotifications = async () => {
      setPageNum(1)
      const url = `${config.REACT_APP_API_BASE_URL}/notifications-distributor` + `?page=` + pageNum + `&limit=10`;
      try {
        const response = await axios.get(
          url,
          {
            headers: headers(),
          }

        );

        if (!isMounted) return;
        const data = response.data.data;

        setNotifCount(response.data.unseen_count);

        setCloneNotification(data);
        switch (filterBy) {
          case 1:
            return setCloneNotification(data);
          case 2:
            return setCloneNotification(
              data.filter((row) => {
                return (
                  row.notif_type === "order submitted" &&
                  row.progress === "success"
                );
              })
            );
          case 3:
            return setCloneNotification(
              data.filter(
                (row) =>
                  row.notif_type === "order confirmed" && row.progress === "success"
              )
            );
          default:
            return setCloneNotification(data);
        }
      } catch (err) {
      }
    };



    const isLoggedIn = async () => {
      try {
        const isLoggedIn = await axios.get(
          `${config.REACT_APP_API_BASE_URL}/my-info`,
          {
            headers: headers(),
          }
        );

        if (isLoggedIn.status === 200) {
          fetchNotifications()
        }

      } catch (error) {

      }
    }




    isLoggedIn()

    return () => {
      isMounted = false;
    };
  }, [filterBy]);

  useEffect(async () => {
    let isUpdated = true
    const showPromiseWarning = () => {
      warning({
        title: 'The server has received too many frequent requests!',
        icon: <img src={WarningIcon} style={{ height: 22, width: 22, position: 'relative', top: 5, left: 15 }} />,
        content: 'Please click OK to wait for 3 seconds and the page will auto-refresh',
        onOk() {
          return new Promise(() => {
            setTimeout(() => {
              window.location.reload()
            }, 3600);
          })
        }
      });
    }

    const updateDistributorName = async () => {
      try {
        const response = await axios.get(
          `${config.REACT_APP_API_BASE_URL}/my-info`,
          {
            headers: headers(),
          }
        );

        if (!isUpdated) return

        const name = await response.data.distributor.name

        if (response.status === 429) {
          showPromiseWarning()
        }

        setDistributorName(name)

      } catch (err) {
      }
    }
    updateDistributorName()

  }, [0])


  useEffect((notifications) => notifications, []);

  const notificationMenu = () => {
    const notificationDescription = (row) => {
      const { notif_type } = row;

      const notificationStatusIconStyle = {
        marginRight: "15px",
      };

      switch (notif_type) {
        case "order submitted":
          if (row.description !== null && row.progress === "success") {
            return (
              <div className="notification-row">
                <img
                  src={OrderSubmittedIcon}
                  alt="order-submitted"
                  style={notificationStatusIconStyle}
                />
                <div>{row.description}</div>
              </div>
            );
          }
        case "order confirmed":
          if (row.description !== null && row.progress === "success") {
            return (
              <div className="notification-row">
                <img
                  src={OrderSubmittedIcon}
                  alt="order-submitted"
                  style={notificationStatusIconStyle}
                />
                <div>{row.description}</div>
              </div>
            );
          }
        case "feedback":
          if (row.progress === "success") {
            return (
              <div className="notification-row">
                <img
                  src={OrderSubmittedIcon}
                  alt="feedback-received"
                  style={notificationStatusIconStyle}
                />
                <div>The feedback from your order test has been received!</div>
              </div>
            );
          }
      }
    };

    const MenuItem = (row, props, type, dateSetting) => {
      const { created_at, sales_ref_number, estimated_delivery } = row;

      const redirectionType = (row) => {
        const { notif_type, response, progress, description } = row;

        const { po_reference, transaction_number, status } = row;

        switch (notif_type) {
          case "order submitted":
            switch (progress) {
              case "success":
                return `/purchase-status?id=${row.order_detail_id}`;
            }
          case "order confirmed":
            switch (progress) {
              case "success":
                return `/purchase-status?id=${row.order_detail_id}`;
            }
        }
      };

      return (
        <Row
          {...props}
          key={row.id}
          className="notification-item"
        // row.status == 0 && "unread-notification
        >
          {row.notif_type === 'feedback' ? (
            <>
              <div className="notification-description">
                {notificationDescription(row)}
                {row.status == 0 && (
                  <div className="notification-active-indicator">
                    <img src={BrownIcon} alt="unread" />
                  </div>
                )}
              </div>
              <label className="notification-date">
                {type === "today"
                  ? dateSetting(row.created_at)
                  : moment(row.created_at).format("LLLL")}
              </label>
            </>
          ) : (
            <Link
              onClick={() => handleUpdateNotification(row.id, row)}
              to={redirectionType(row)}
            >
              <div className="notification-description">
                {notificationDescription(row)}
                {row.status == 0 && (
                  <div className="notification-active-indicator">
                    <img src={BrownIcon} alt="unread" />
                  </div>
                )}
              </div>
              <label className="notification-date">
                {type === "today"
                  ? dateSetting(row.created_at)
                  : moment(row.created_at).format("LLLL")}
              </label>
            </Link>
          )
          }

        </Row >
      );
    };

    const handleNotificationSelect = (key) => setFilterBy(key);

    const handleUpdateNotification = async (id, row) => {
      const { notif_type, response: responseRow } = row;
      try {
        await axios.post(
          `${config.REACT_APP_API_BASE_URL}/notification/${id}`,
          { status: 1 },
          {
            headers: headers(),
          }
        );
        const newData = await axios.get(
          `${config.REACT_APP_API_BASE_URL}/notifications-distributor` + `?page=` + (pageNum) + `&limit=10`,
          {
            headers: headers(),
          }
        );
        setCloneNotification(newData.data.data);
        await axios.get(
          `${config.REACT_APP_API_BASE_URL}/notifications-distributor` + `?page=` + (pageNum) + `&limit=10`,
          {
            headers: headers(),
          }
        );
        window.location.reload();
      } catch (err) {
        //
      }
    };

    if (cloneNotification) {
      const Today = (props) => {
        const todayDateSetting = (date) => {
          moment.updateLocale("en", {
            relativeTime: {
              future: "in %s",
              past: "%s ago",
              s: (number) => number + "s ago",
              ss: "%ds ago",
              m: "1m ago",
              mm: "%dm ago",
              h: "1h ago",
              hh: "%dh ago",
              d: "1d ago",
              dd: "%dd ago",
              M: "a month ago",
              MM: "%d months ago",
              y: "a year ago",
              yy: "%d years ago",
            },
          });

          const secondsElapsed = moment().diff(date, "seconds");
          const dayStart = moment().startOf("day").seconds(secondsElapsed);

          if (secondsElapsed > 300) {
            return moment(date).fromNow(true);
          } else if (secondsElapsed < 60) {
            return dayStart.format("s") + "s ago";
          } else {
            return (
              dayStart.format("m") + "m and " + dayStart.format("ss") + "s ago"
            );
          }
        };

        const isHaveToday = cloneNotification.filter(
          (row) => moment(row.created_at).format("LL") === moment().format("LL")
        );
        return (
          isHaveToday.length !== 0 && (
            <>
              <h2 className="notification-secondary-header">Today</h2>
              {cloneNotification
                .sort(
                  (a, b) =>
                    moment(b.created_at).unix() - moment(a.created_at).unix()
                )
                .filter(
                  (row) =>
                    moment(row.created_at).format("LL") ===
                    moment().format("LL")
                )
                .map((row) => MenuItem(row, props, "today", todayDateSetting))}
            </>
          )
        );
      };
      const Yesterday = (props) => {
        const isHaveYesterday = cloneNotification.filter(
          (row) =>
            moment(row.created_at).format("LL") ===
            moment().subtract(1, "days").format("LL")
        );
        return (
          isHaveYesterday.length !== 0 && (
            <div>
              <h2 className="notification-secondary-header">Yesterday</h2>
              {cloneNotification
                .sort(
                  (a, b) =>
                    moment(b.created_at).unix() - moment(a.created_at).unix()
                )
                .filter(
                  (row) =>
                    moment(row.created_at).format("LL") ===
                    moment().subtract(1, "days").format("LL")
                )
                .map((row) => MenuItem(row, props))}
            </div>
          )
        );
      };
      const Rest = (props) => {
        const months = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12];

        const outerData = months.map((row1) => {
          return {
            data: cloneNotification
              .filter((row) => moment(row.created_at).format("M") == row1)
              .sort(
                (a, b) =>
                  moment(b.created_at).unix() - moment(a.created_at).unix()
              ),
            month: row1,
            year: cloneNotification
              .filter((row) => moment(row.created_at).format("M") == row1)
              .map((row) => moment(row.created_at).year())
              .reduce(
                (acc, cur) => (acc.includes(cur) ? acc : [...acc, cur]),
                []
              )[0],
          };
        });
        return outerData
          .filter((row) => row.data.length !== 0)
          .map((row) => {
            const monthData = (perMonth) => {
              const year = row.year;
              switch (perMonth) {
                case 1:
                  return `January ${year}`;
                case 2:
                  return `February ${year}`;
                case 3:
                  return `March ${year}`;
                case 4:
                  return `April ${year}`;
                case 5:
                  return `May ${year}`;
                case 6:
                  return `June ${year}`;
                case 7:
                  return `July ${year}`;
                case 8:
                  return `August ${year}`;
                case 9:
                  return `September ${year}`;
                case 10:
                  return `October ${year}`;
                case 11:
                  return `November ${year}`;
                default:
                  return `December ${year}`;
              }
            };
            return (
              <>
                <h2 className="notification-secondary-header">
                  {row.data.filter(
                    (row) =>
                      moment(row.created_at).format("LL") !==
                      moment().format("LL") &&
                      moment(row.created_at).format("LL") !==
                      moment().subtract(1, "days").format("LL")
                  ).length === 0
                    ? ""
                    : monthData(row.month)}
                </h2>
                {row.data
                  .filter(
                    (row) =>
                      moment(row.created_at).format("LL") !==
                      moment().format("LL") &&
                      moment(row.created_at).format("LL") !==
                      moment().subtract(1, "days").format("LL")
                  )
                  .map((row) => MenuItem(row, props))}
              </>
            );
          })
          .reverse();
      };

      return (

        <Menu className="notification-menu">
          <div className="notification-title-container">
            <h3 className="notification-title">Notification</h3>
          </div>
          <label className="notification-sortby">Sort by</label>
          <Select
            style={{ width: 150, margin: "10px" }}
            onChange={handleNotificationSelect}
            defaultValue={1}
            className="notification-sorter"
            dropdownClassName="notification-sorter-dropdown"
          >
            <Select.Option value={1}>All</Select.Option>
            <Select.Option value={2}>Order Submitted</Select.Option>
            <Select.Option value={3}>Order Confirmed</Select.Option>
          </Select>
          <Today />
          <Yesterday />
          <Rest />
          {cloneNotification.length === 0 && (
            <div className="notification-zero">0 Notification</div>
          )}
          <div className="notification-footer">
            <Button type="text" className="notification-page-button" disabled={pageNum == 1 ? true : false} onClick={() => {
              // fetchMoreNotifications(-1);
              setNotifVisible(true)
              setPageNum(pageNum - 1)
            }} > &lt; Previous</Button>
            <Button type="text" className="notification-page-button" disabled={pageNum >= (notifCount / 10) ? true : false} onClick={() => {
              // fetchMoreNotifications(1);
              setNotifVisible(true)
              setPageNum(pageNum + 1)
            }} >Next &gt; </Button>
          </div>
        </Menu>
      );
    }
  };

  const { location, paths } = props;

  let selectedKeys = _.chain(paths)
    .filter((path) => new RegExp(`\\b${path.slug}\\b`).test(location.pathname))
    .map("slug")
    .value();
  selectedKeys = !selectedKeys.length
    ? _.find(paths, ["default", true]).slug
    : selectedKeys[0] === "purchase-status" ||
      selectedKeys[0] === "container-dashboard" ||
      selectedKeys[0] === "so-list" ||
      selectedKeys[1] === "order-status"
      ? ["summary-dashboard"]
      : selectedKeys;

  function handleCloseModal() {
    setIsModalOpen(false);
  }

  function handleIsModalOpen() {
    isModalOpen = !isModalOpen;
    setIsModalOpen(isModalOpen);
  }

  function handleLogout() {
    setLogoutSpin(true);
    setTimeout(toClear, 1200);
  }

  function toClear() {
    setIsLoggedIn(false);
    clearLocalStorage();
    props.handleLoginStatus(false);
    props.history.push("/");
  }

  async function openUserManual() {
    // window.open(process.env.REACT_APP_USER_MANUAL_URL);
    const url = `${process.env.REACT_APP_API_BASE_URL}/download-user-manual`;
    await axios.get(
      url,
      {
        headers: headers(),
        responseType: 'arraybuffer'
      }
    ).then((res) => {
      let blob = new Blob([res.data], { type: 'application/pdf' })
      let link = document.createElement('a')
      link.href = window.URL.createObjectURL(blob)
      link.download = 'URC Flour App - User Manual.pdf'
      link.click()
    });
  }

  function orderSummary() {
    return (
      <Menu>
        <Menu.Item key="0">
          <Link to="/summary-dashboard"><span className="capitalize">OMS Summary Dashboard</span></Link>
        </Menu.Item>
        <Menu.Item key="1">
          <Link to="/order-status"><span className="capitalize">Order History</span></Link>
        </Menu.Item>
      </Menu>
    );
  }

  function userProfile() {
    return (
      <Menu>
        <Menu.Item key="0">
          <Link to="/user-profile"><span className="capitalize">User Profile</span></Link>
        </Menu.Item>
        <Menu.Item key="1">
          <Link to="#" onClick={openUserManual}><span className="capitalize">User Manual</span></Link>
        </Menu.Item>
      </Menu>
    );
  }

  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const { loggedIn, handleLoginStatus, loggedData, customer } = props;

  function isCustomerActive(activeRequired) {
    if (activeRequired !== true) return true;
    return (loggedData.user.customer?.status || customer.status === true) && customer.customer_group !== null;
  }

  useEffect(() => {
    (loggedIn || isLocalStorageEmpty()) && handleLoginStatus(true);
    (loggedIn || isLocalStorageEmpty()) && setIsLoggedIn(true);
    const urlParams = new URLSearchParams(window.location.search);
    let redirectTo = urlParams.get("redirectTo");
    let poNumber = urlParams.get("po_number");

    if (
      (loggedIn && redirectTo === "container-dashboard") ||
      (isLocalStorageEmpty() && redirectTo === "container-dashboard")
    ) {
      // Needs to delay so that headers() in request will be ready
      setTimeout(() => {
        props.history.push(`/container-dashboard?po_number=${poNumber}`);
      }, 1000);
    }
    // handleLoginStatus(isLoggedIn)
    setLogoutSpin(false);
  }, [handleLoginStatus, loggedIn, props.history]);

  let [isModalOpen, setIsModalOpen] = useState(false);
  const urlParams = new URLSearchParams(window.location.search);
  const modalLogin = urlParams.get("modalLogin");
  useEffect(() => {
    if (modalLogin === "true" && !isLocalStorageEmpty()) {
      setIsModalOpen(true);
    }
  }, [modalLogin]);
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);

  const [isSuccessModal, setSuccessModal] = useState(false);
  useEffect(() => {
    props.loggedIn &&
      setSuccessModal(true)
  }, [props.loggedIn]);


  const [isScrolled, setScrolled] = useState(false);
  useEffect(() => {
    const handleScroll = (e) => {
      var scrollPosY = window.pageYOffset | document.body.scrollTop;
      setScrolled(scrollPosY > 100);
    };
    document.addEventListener("scroll", handleScroll, { passive: true });
    return () => {
      // This cleans up the event handler when the component unmounts.
      document.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const [isLogoutSpin, setLogoutSpin] = useState(false);

  const filteredPaths = (menu) =>
    _.filter(
      paths,
      (path) =>
        (path.authRequired ? isLoggedIn : true) &&
        path.placements &&
        path.placements.includes("Header") &&
        path.menu === menu &&
        isCustomerActive(path.activeRequired)
    );
  return (
    <Fragment>
      <Layout.Header
        className={`animated fadeIn scrolled`}
      >
        <MediaQuery minWidth={1224}>
          <Link to="/" className="logo" />
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              flexWrap: "nowrap",
            }}
          >
            <Menu
              className="ant-menu-urc left"
              mode="horizontal"
              defaultSelectedKeys={selectedKeys}
              selectedKeys={selectedKeys}
              style={{ float: "left", lineHeight: "61px" }}
            >
              {_.map(filteredPaths('left'), (path) => (
                <Menu.Item key={path.slug}>
                  <Link data-menu-label={path.label} to={path.route}>
                    {path.label}
                  </Link>
                </Menu.Item>
              ))}
            </Menu>

            <Menu
              className="ant-menu-urc right"
              mode="horizontal"
              defaultSelectedKeys={selectedKeys}
              selectedKeys={selectedKeys}
              style={{ float: "right", lineHeight: "61px" }}
            >
              {_.map(filteredPaths('right'), (path) => path.slug === 'user-profile' && isLoggedIn? (
                <Menu.Item key={path.slug}>
                  <Dropdown overlay={userProfile} trigger={["click"]} getPopupContainer={trigger => trigger.parentNode}>
                    <Link data-menu-label={path.label} to={path.route}>
                      {customer.name}
                      <Icon type="down" />
                    </Link>
                  </Dropdown>
                </Menu.Item>
              ) : null)}
              {isLoggedIn && (
                <Menu.Item {...props}>
                  <Dropdown
                    overlay={notificationMenu}
                    trigger={["click"]}
                    placement="bottomRight"
                    visible={notifVisible}
                    onClick={() => {
                      if (notifVisible == false) setNotifVisible(true)
                      else setNotifVisible(false)
                    }
                    }
                    getPopupContainer={trigger => trigger.parentNode}
                  >
                    <Badge
                      style={{ background: "#774625" }}
                      count={
                        // notifications.filter((row) => row.status === 0).length
                        notifCount
                      }
                    >
                      <Icon
                        type="bell"
                        style={{ fontSize: "1.2rem", color: "#a80f1b" }}
                        theme="filled"
                      />
                    </Badge>
                  </Dropdown>
                </Menu.Item>

              )}
              <Menu.Item
                className="login-signup"
                onClick={() => {
                  isLoggedIn ? handleLogout() : handleIsModalOpen();
                }}
              >
                <a>
                  {isLoggedIn ? (
                    <span>
                      <Spin
                        size="small"
                        spinning={isLogoutSpin}
                        style={{ marginRight: "10px" }}
                      />
                      Logout
                    </span>
                  ) : (
                    <span>Customer Login</span>
                  )}
                </a>
              </Menu.Item>

            </Menu>
          </div>
        </MediaQuery>
        <MediaQuery maxWidth={1224}>
          <Button
            type="link"
            icon="menu"
            className="drawer-toggle"
            size="large"
            onClick={() => setIsDrawerOpen(true)}
          />
          <Link to="/" className="logo" />
          <Drawer
            title={
              <Card
                className={isLoggedIn ? "has-content" : ""}
                bordered={false}
                title={
                  <Link
                    to="/"
                    onClick={() => setIsDrawerOpen(false)}
                    className="logo"
                  />
                }
                extra={
                  <Button
                    type="link"
                    icon="arrow-left"
                    onClick={() => setIsDrawerOpen(false)}
                  />
                }
              >
              </Card>
            }
            className="main-menu-drawer"
            placement="left"
            closable={false}
            onClose={() => setIsDrawerOpen(false)}
            visible={isDrawerOpen}
          >
            <Menu
              className="ant-menu-urc"
              mode="inline"
              defaultSelectedKeys={selectedKeys}
              selectedKeys={selectedKeys}
            >
              {_.map(filteredPaths('left'), (path) => (
                <Menu.Item key={path.slug}>
                  <Link
                    data-menu-label={path.label}
                    to={path.route}
                    onClick={() => setIsDrawerOpen(false)}
                  >
                    {path.label}
                  </Link>
                </Menu.Item>
              ))}
              <Menu.Item key="0">
                <Link to="/user-profile"><span className="capitalize">USER PROFILE</span></Link>
              </Menu.Item>
              <Menu.Item key="1">
                <Link to="#" onClick={openUserManual}><span className="capitalize">USER MANUAL</span></Link>
              </Menu.Item>
            </Menu>
            <Menu
              className="ant-menu-urc"
              mode="inline"
              defaultSelectedKeys={selectedKeys}
              selectedKeys={selectedKeys}
            >
              <Menu.Item
                className="login-signup"
                onClick={() => {
                  isLoggedIn ? handleLogout() : handleIsModalOpen();
                }}
              >
                <a>
                  {isLoggedIn ? (
                    <span>
                      <Spin
                        size="small"
                        spinning={isLogoutSpin}
                        style={{ marginRight: "10px" }}
                      />
                      Logout
                    </span>
                  ) : (
                    <span>Customer Login</span>
                  )}
                </a>
              </Menu.Item>
            </Menu>
          </Drawer>
        </MediaQuery>
      </Layout.Header>
      <Modal
        className="login-success-modal"
        title="Customer Log In"
        visible={isModalOpen}
        zIndex={99999}
        width={400}
        style={{ top: "20", right: "5%", float: "right", margin: -10 }}
        onCancel={() => handleCloseModal()}
        footer={null}
      >
        <div style={{ padding: "0 25px" }}>
          <Login closeModal={handleCloseModal} />
        </div>
      </Modal>
      <Modal
        title="Successfully Logged In!"
        centered
        visible={isSuccessModal}
        onCancel={() => setSuccessModal(false)}
        zIndex={99999}
        width={350}
        footer={null}
      >
        <div style={{ fontStyle: "italic" }} id="Client_Login">
          <p>
            You are :{" "}
            <b>
              {props.loggedData.user.customer &&
                props.loggedData.user.customer.name
                ? props.loggedData.user.customer.name
                : ""}
            </b>
          </p>
          <p>
            and your username is : <b>{props.loggedData.user.username}</b>
          </p>
        </div>
      </Modal>
    </Fragment >
  );
}
function mapStateToProps(state) {
  return {
    loggedIn: state.login.loggedIn,
    loggedData: state.login.loggedData,
    customer: state.distributor.data?.customer || []
  };
}



export default withRouter(connect(mapStateToProps)(Header));
