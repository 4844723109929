import React, { Component } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import moment from "moment";
import {
  Field,
  reduxForm
} from "redux-form";
import { downloadFile } from "./downloadFile";
import {
  fetchDistributor,
  fetchDataFromSap,
  fetchPoStatus,
  postFeedback,
  fetchNotifications,
  approveRequest,
  rejectRequest,
  loadStorageLocation,
  removeOrderAttachment,
  uploadOrderAttachment,
  downloadAttachment,
  retrieveFileUrl
} from "../../actions";
import * as _ from "lodash";
import {
  Layout,
  Row,
  Col,
  Input,
  Select,
  Icon,
  Table,
  List,
  Button,
  message,
  Modal,
  Checkbox,
  DatePicker,
  Popover
} from "antd";
import MediaQuery from "react-responsive";

import { numFormat } from "../../utilities/format";
import DocumentMeta from "react-document-meta";
import {
  fetchPermissions,
  fetchRecentOrders,
  fetchOrderStatus,
  postPoNumber,
  fetchDistributorOrderSummary
} from "../../actions";

import "./OrderStatus.scss";
import ModalAttachment from './components/ModalAttachment';

import rate1 from "../../assets/svg/rate-1.svg";
import rate2 from "../../assets/svg/rate-2.svg";
import rate3 from "../../assets/svg/rate-3.svg";
import rate4 from "../../assets/svg/rate-4.svg";
import rate5 from "../../assets/svg/rate-5.svg";

const CheckboxGroup = Checkbox.Group;
const plainOptions = ["EXCEL", "CSV"];

const { Option } = Select;
const { RangePicker } = DatePicker;
const { Content } = Layout;

const feedbackEmoji = [
  {
    rate: 1,
    svg: rate1,
  },
  {
    rate: 2,
    svg: rate2,
  },
  {
    rate: 3,
    svg: rate3,
  },
  {
    rate: 4,
    svg: rate4,
  },
  {
    rate: 5,
    svg: rate5,
  },
];

const renderInput = ({
  input,
  label,
  type,
  className,
  readOnly,
  meta: { touched, error, warning },
}) => (
  <div style={{ height: 50 }}>
    <input
      {...input}
      className={className}
      placeholder={label}
      readOnly={readOnly}
      type={type}
    />
    {touched &&
      ((error && <span style={{ color: "red" }}>{error}</span>) ||
        (warning && <span>{warning}</span>))}
  </div>
);

const feedbackValidator = (value) =>
  value ? undefined : "Please provide your comments and suggestions";

class RecentOrder extends Component {
  orderStatusArray = [];
  // Inital State for the sorting
  sortState = {
    sortBy: 'order_detail.created_at',
    orderBy: 'desc'
  };

  jsonFilter = {};

  excludedColumns = [
    'Type',
    'Source',
    'Payment Term',
    'Delivery Date',
    'ORDER STATUS',
    'ORDERED QUANTITY',
    'AMOUNT (IN USD)',
    'TOTAL WEIGHT'
  ];

  // Initial state for searching
  searchState = {};

  constructor(props) {
    super(props);
    this.state = {
      orders: [],
      expand: [],
      tableData: [],
      keyword: "",
      filterBy: "order_detail.created_at",
      activeRate: null,
      showDownloadModal: false,
      showApprovalsModal: false,
      selectedRow: null,
      selectedRowKeys: [],
      indeterminate: false,
      checkAll: false,
      checkedList: [],
      showFeedbackModal: false,
      showQuestion: false,
      showThankYouModal: false,
      isPostingFeedback: false,
      isSendingResponse: false,
      // isApprovalsOpen: true,
      isDownloadOpen: false,
      showStatusUpdateModal: false,
      editPoNumberId: null,
      poInput: "",
      poNumber: "",
      isOpen: false,
      isUniquePoNumber: false,
      notifications: [],
      isApproveType: [""],
      urlTransactionNo: "",
      urlPoRef: "",
      urlResponse: "",
      radio: 'all',
      orderSummary: {},
      filterByValue: 'Order Date',
      showModalAttachment: false,
      selectedId: 0,
      page: 1
    };
    this.imageUploader = React.createRef();
    this.onSelectChange = this.onSelectChange.bind(this);
  }

  showDownloadOptions = () => {
    this.setState({ isDownloadOpen: true });
  };

  hideDownloadOptions = () => {
    this.setState({ isDownloadOpen: false });
  };

  onChangeCheckbox = (checkedList) => {
    this.setState({
      checkedList,
      indeterminate:
        !!checkedList.length && checkedList.length < plainOptions.length,
      checkAll: checkedList.length === plainOptions.length,
    });
  };

  onCheckAllChange = (e) => {
    this.setState({
      checkedList: e.target.checked ? plainOptions : [],
      indeterminate: false,
      checkAll: e.target.checked,
    });
  };

  onSelectChange(selectedRowKeys) {
    this.setState({ selectedRowKeys });
  }

  componentDidMount() {
    this.props.fetchPermissions();
    this.props.loadStorageLocation({ is_paginated: false });
    this.props.fetchDistributorOrderSummary();
    this.props.fetchRecentOrders();
    this.props.fetchDataFromSap();
    this.props.fetchDistributor();
    this.props.fetchNotifications();
    this.props.fetchOrderStatus(this.orderStatusArray)

    const urlParams = new URLSearchParams(window.location.search);
    const response = urlParams.get("response");
    const transaction_number = urlParams.get("transaction_number");
    const po_reference = urlParams.get("po_reference");

    if (this.state.isApprovalsOpen) {
      if (response) {
        this.setState({
          showApprovalsModal: true,
          urlTransactionNo: transaction_number,
          urlPoRef: po_reference,
          urlResponse: response,
          isApproveType: ["1"],
        });
      }
    }
  }

  componentWillReceiveProps(nextProps) {

    if (this.props.notificationsData.data !== nextProps.notificationsData.data) {
      this.setState({ notifications: nextProps.notificationsData.data });
    }

    if (this.props.orderStatus.data !== nextProps.orderStatus.data) {
      this.setState({ tableData: nextProps.orderStatus.data });
    }

    if (this.props.distributorOrderSummary.data !== nextProps.distributorOrderSummary.data) {
      this.setState({ orderSummary: nextProps.distributorOrderSummary.data });
    }
  }

  orderStatusDisplay(status) {
    status = "inProgress";
    if (status === "inProgress") {
      return (
        <div
          style={{
            padding: 10,
            backgroundColor: "#EF9643",
            borderRadius: 20,
            textAlign: "center",
          }}
        >
          <span style={{ whiteSpace: "nowrap", color: "white" }}>
            IN PROGRESS
          </span>
        </div>
      );
    }
  }

  // The first params is the search value, second param are the search keys in table
  handleSearch = (searchTerm, searchKeys) => {
    // //function inside function, this will convert the dot string into actual object
    this.setState({ keyword: searchTerm, page: 1 });

    // searchTerm = Array.isArray(searchTerm) ? searchTerm.filter(date => date !== '') : searchTerm.trim();
    this.searchState = searchTerm.length < 1 ? {} : {
      searchValue: searchTerm,
      searchField: searchKeys
    };

    this.props.fetchOrderStatus(this.orderStatusArray, {
      ...this.sortState, ...{
        page: 1
      }, ...this.searchState, ...this.jsonFilter
    });
  };

  handleSourceFilter = (value) => {
    this.setState({ page: 1 })
    this.jsonFilter = value === 'all' ? {} : {
      searchField: 'order_detail.source',
      searchValue: value
    }

    this.props.fetchOrderStatus(this.orderStatusArray, {
      ...this.sortState, ...{
        page: 1
      }, ...this.searchState, ...this.jsonFilter
    });
  }

  fetchOrders = () => {
    this.props.fetchOrderStatus(this.orderStatusArray, {
      ...this.sortState, ...this.searchState, ...this.jsonFilter, ... { page: this.state.page }
    });
  }

  handleFilterBy = (value, isSaveState = true) => {
    let category = ''
    value = value.toUpperCase();
    switch (value) {
      case 'ORDER DATE':
        category = 'order_detail.created_at'
        break;
      case 'ORDER NUMBER':
        category = 'order_transaction_id'
        break;
      case 'TRANSACTION REFERENCE NUMBER':
        category = 'sales_order_number'
        break;
      case 'DELIVERED TO':
        category = 'sold_to.name'
        break;
      case 'CUSTOMER':
        category = 'payer.name'
        break;
      case 'CUSTOMER GROUP':
        category = 'payer.customer_group'
        break;
      case 'CUSTOMER GROUP 2':
        category = 'payer.customer_group_2'
        break;
    }

    if (isSaveState) {
      this.setState({ filterBy: category, keyword: "", filterByValue: value });
    } else {
      return category;
    }
  };

  handleClickRate = (rate) => {
    this.setState({ activeRate: rate, showQuestion: true });
  };
  handleApproveRequest = (notifId) => {
    const { approveRequest } = this.props;

    this.setState({ isSendingResponse: true });

    approveRequest(notifId).then((response) => {
      if (!response.error) {
        this.setState({
          showApprovalsModal: false,
          isSendingResponse: false,
        });
      } else {
        this.setState({
          showApprovalsModal: false,
          isSendingResponse: false,
        });
      }
      this.props.fetchNotifications();
      this.props.history.push("/order-status");
    });
  };

  handleRejectRequest = (notifId) => {
    const { rejectRequest } = this.props;

    this.setState({ isSendingResponse: true });

    rejectRequest(notifId).then((response) => {
      if (!response.error) {
        this.setState({
          showApprovalsModal: false,
          isSendingResponse: false,
        });
      } else {
        this.setState({
          showApprovalsModal: false,
          isSendingResponse: false,
        });
      }
      this.props.fetchNotifications();
      this.props.history.push("/order-status");
    });
  };

  handleFeedbackSubmit = (values) => {
    const { activeRate, distributorOrderId } = this.state;
    const { comments } = values;
    const { distributor, postFeedback } = this.props;

    if (!activeRate) {
      message.error("Please input your feedback");
      return;
    }

    const data = {
      po_date: moment().format("YYYY-MM-DD"),
      distributor_id: distributor.data.distributor_id,
      comments: comments,
      feedback_score: activeRate,
      feedback_type: "order fulfillment",
      distributor_order_id: distributorOrderId,
    };

    this.setState({ isPostingFeedback: true });

    postFeedback(data).then((response) => {
      if (!response.error) {
        this.setState({
          activeRate: null,
          showThankYouModal: true,
          showFeedbackModal: false,
          isPostingFeedback: false,
        });
      } else {
        this.setState({
          activeRate: null,
          showFeedbackModal: false,
          isPostingFeedback: false,
        });
      }
      this.props.history.push("/order-status");
    });
  };

  handleSavePoNumber = (orderId) => {
    const distributorCustomerCode =
      this.props.distributor &&
      this.props.distributor.data &&
      this.props.distributor.data.distributor.customer_code;
    const { poInput, isUniquePoNumber } = this.state;

    if (isUniquePoNumber) {
      message.error("Po Number must be Unique");
    } else {
      this.props.postPoNumber(orderId, poInput).then((response) => {
        if (!response.error) {
          this.props.sapData &&
            this.props.sapData.data &&
            this.props.sapData.data.map((data) => {
              if (
                Number(data.customer_po_code) ===
                Number(distributorCustomerCode)
              ) {
                this.orderStatusArray.push({ ...data });
              }
            });
          this.setState({ page: 1 })
          this.props.fetchOrderStatus(this.orderStatusArray, {
            ...this.sortState, ...{
              page: 1
            }, ...this.searchState, ...this.jsonFilter
          }).then((response) => {
            if (!response.error) {
              this.setState({ editPoNumberId: null, poInput: "" });
            }
          });
        }
      });
    }
  };

  onChangePoNumber = (poNumber) => {
    poNumber = poNumber.replace(
      /[`~!@#$%^&*()_|+=?;:'",.<>\{\}\[\]\\\/]/gi,
      ""
    );

    const findUniquePoNumber = this.state.tableData.data.find(
      (row) => row.poNumber.toLowerCase() == poNumber.toLowerCase()
    );

    this.setState({
      poInput: poNumber,
      isUniquePoNumber: findUniquePoNumber === undefined ? false : true,
    });
  };

  download(downloadType, rowDataArray) {
    const { checkedList } = this.state
    checkedList.includes('EXCEL') && downloadFile.EXCEL(rowDataArray);
    checkedList.includes('CSV') && downloadFile.CSV(rowDataArray);
    checkedList.includes('PDF') && downloadFile.PDF(rowDataArray);
  }

  getStatus = sapData => {
    sapData = sapData || "";
    let status;
    if (sapData["billing_document"]) status = "invoiced";
    else if (sapData["delivery"]) status = "forLoading";
    else if (sapData["sales_document"]) status = "produced";
    else if (!sapData["sales_document"] && !sapData["delivery"] && !sapData["billing_document"]) status = "forProduction";
    else status = "";
    return status;
  };

  changeAttachmentListVisibility = (isShown, orderId = 0, e = null) => {
    if (e) {
      e.stopPropagation()
    }

    this.setState({
      showModalAttachment: isShown,
      selectedId: orderId
    })
  }

  render() {
    const {
      filterBy,
      activeRate,
      showFeedbackModal,
      showThankYouModal,
      isPostingFeedback,
      showStatusUpdateModal,
      showDownloadModal,
      checkedList,
      keyword,
      selectedRowKeys,
      radio,
      orderSummary,
      filterByValue,
      showModalAttachment,
      selectedId,
      tableData
    } = this.state;
    let { notifications } = this.state;
    notifications = notifications ? notifications : [];


    const urlParams = new URLSearchParams(window.location.search);
    const {
      myPermissions,
      orderStatus,
      handleSubmit,
      sapData,
      distributor,
      removeOrderAttachment,
      uploadOrderAttachment,
      downloadAttachment,
      retrieveFileUrl
    } = this.props;
    let storageLocationData = orderStatus.storageLocationData || [];
    let dataSource = tableData || [];
    let orderStatusData = dataSource.data || [];

    let downloadDataArray = []
    orderStatusData.map(data => {
      selectedRowKeys.map(rowKey => {
        if (data.id === rowKey) {
          downloadDataArray.push(data)
        }
      })
    })

    let isLoading =
      orderStatus.fetching || orderStatus.postPoLoading || sapData.fetching;

    const rowSelection = {
      selectedRowKeys,
      onChange: this.onSelectChange,
      hideDefaultSelections: true,
      selections: [
        {
          key: "id",
          text: "Select Odd Row",
          onSelect: (changableRowKeys) => {
            let newSelectedRowKeys = [];
            newSelectedRowKeys = changableRowKeys.filter((key, index) => {
              if (index % 2 !== 0) {
                return false;
              }
              return true;
            });
            this.setState({ selectedRowKeys: newSelectedRowKeys });
          },
        },
      ]
    };

    const payment_term = {
      'ZCIA': 'Cash In Advance', // NOTE: remove on round to of testing
      'ZFCI': 'Cash In Advance',
      'ZFDO': 'Credit Term',
      'ZFDL': 'Cash On Delivery',
      'ZFDK': 'Special Term'
    };

    let columns = [
      {
        title: "ORDER DATE",
        dataIndex: "created_at",
        key: "Order Date",
        sorter: (a, b) => { /* moment(a.poCreatedAt).unix() - moment(b.poCreatedAt).unix() */ },
        render: ({ }, value) => <span>{moment(value.created_at).format('YYYY-MM-DD')}</span>,
      },
      {
        width: 110,
        title: (
          <span style={{ padding: 0 }}>
            ORDER NUMBER
          </span>
        ),
        dataIndex: "order_transaction_id",
        sorter: (a, b) => { /* moment(a.poCreatedAt).unix() - moment(b.poCreatedAt).unix() */ },
        key: "Order Number",
        render: (value, i) => (
          <Link
            to={`/purchase-status?id=${i.id}`}
            style={{
              color: "#0785E3",
              fontWeight: "normal",
              fontFamily: "Montserrat",
              padding: 0,
            }}
          >
            {value}
          </Link>
        ),
      },
      {
        width: 120,
        title: (
          <span style={{ padding: 0 }}>
            TRANSACTION REFERENCE NUMBER
          </span>
        ),
        dataIndex: "sales_order_number",
        key: "Transaction Reference Number",
        sorter: (a, b) => {
          // Number(a.poNumber.split("-").pop()) -
          // Number(b.poNumber.split("-").pop())
        },
        render: (value, i) => (
          <div
            style={{ display: "flex", justifyContent: "space-between" }}
            onClick={(e) => {
              e.stopPropagation();
            }}
          >
            <div
              style={{
                display: "flex",
                alignItems: "center",
                margin: "0",
              }}
            >
              <Link
                to={`/purchase-status?id=${i.id}`}
                style={{
                  color: "#0785E3",
                  fontWeight: "normal",
                  fontFamily: "Montserrat",
                  // whiteSpace: "nowrap"
                }}
              >
                {value}
              </Link>
            </div>
          </div>
        ),
      },
      {
        title: "ORDER STATUS",
        dataIndex: "status",
        key: "ORDER STATUS",
        align: "center",
        render: (value, row) => {
          let statusClassName = row.status === 'Completed' ? 'invoiced' : 'cancelled'
          statusClassName = ['Submitted', 'Confirmed', 'Approved', 'Partially Dispatched', 'Blocked', 'For Review', 'For Salesman Approval'].includes(row.status) ? 'in-process' : statusClassName
          const status = ["For Review", "For Salesman Approval"].includes(row.status) ? "Confirmed" : row.status
          return (
            <div className="order-status-tag">
              <span className={statusClassName}>{status}</span>
            </div>
          );
        },
      },
      {
        title: (
          <span>
            DELIVERED TO
          </span>
        ),
        dataIndex: "sold_to.name",
        key: "Delivered To",
        align: "right",
        sorter: (a, b) => {
          // Number(a.poNumber.split("-").pop()) -
          // Number(b.poNumber.split("-").pop())
        },
      },
      {
        title: "CUSTOMER",
        dataIndex: "customer.name",
        key: "Customer",
        sorter: true
      },
      {
        title: "CUSTOMER GROUP",
        dataIndex: "customer.customer_group",
        key: "Customer Group",
        sorter: true
      },
      {
        title: "CUSTOMER GROUP 2",
        dataIndex: "customer.customer_group_2",
        key: "Customer Group 2",
        sorter: true
      },
      {
        title: (
          <span>
            ORDERED
            <br />
            QUANTITY
          </span>
        ),
        dataIndex: "total_quantity",
        key: "ORDERED QUANTITY",
        align: "right",
      },
      {
        title: (
          <span>
            QUANTITY (IN KG)
          </span>
        ),
        dataIndex: "order_material",
        key: "TOTAL WEIGHT",
        align: "right",
        render: (value) => {
          const total_weight = value.reduce(function (res, item) {
            return res + (item.weight);
          }, 0);
          return numFormat("dot/comma", total_weight || 0)
        }
      },
      {
        title: (
          <span>
            ORDERED Amount
            <br />
            (PHP VAT-IN)
          </span>
        ),
        dataIndex: "order_price",
        key: "AMOUNT (IN USD)",
        align: "right",
        render: (value) => <span>PHP {numFormat("dot/comma", (value?.vat_sales + value?.vat_amount) || 0)}</span>,
      },
      {
        title: (
          <span>
            AMOUNT FOR PAYMENT
          </span>
        ),
        dataIndex: "order_price",
        key: "AMOUNT (IN USD)",
        align: "right",
        render: (value) => <span>PHP {numFormat("dot/comma", value?.total_amount - value?.ewt_amount) || "0.00"}</span>,
      },
      {
        title: "DELIVERY DATE",
        dataIndex: "delivery_details.estimated_delivery_date",
        key: "Delivery Date",
        align: "right",
      },
      {
        title: (<span>TYPE</span>),
        dataIndex: "delivery_details.type",
        key: "Type",
        align: "right",
        render: (value) => {
          return (<span style={{ textTransform: "capitalize" }}>{value}</span>)
        },
      },
      {
        title: (<span>SOURCE</span>),
        dataIndex: "shipping_point",
        key: "Source",
        render: (value) => {
          return value ? (
            <span>
              {value.map((shipping_point) => {
                return shipping_point.description
              }).join(', ')}
            </span>
          ) : ''
        }
      },
      {
        title: (<span>PAYMENT TERM</span>),
        dataIndex: "payment_term",
        key: "Payment Term",
        align: "left",
        render: ({ }, value) => {
          let color = value.status === "invoiced" ? " #479667" : "#EF9643";
          return (
            <span style={{ color: color, fontSize: 14, fontWeight: 600, overflowWrap: 'break-word', wordWrap: 'break-word' }}>
              {payment_term[value.payment_term]}
            </span>
          );
        },
      },
      {
        title: (<span>Attachment</span>),
        dataIndex: "id",
        key: "attachment",
        align: "left",
        sorter: false,
        render: (value) => {
          return (<div>
            <Popover content={(<p>Edit Attachments</p>)}>
              <span onClick={(e) => this.changeAttachmentListVisibility(true, value, e)} style={{ cursor: 'pointer', margin: 5 }}>
                <Icon type="edit" style={{ fontSize: '1.5em' }} />
              </span>
            </Popover>
          </div>)
        },
      }
    ];


    const meta = {
      title: "Order History - URC Flour Division",
      description:
        "URC Flour Division seeks global awareness for its brands, enter new territories and explore untapped business opportunities. Learn more about us today!",
      canonical: window.location.href,
      meta: {
        charset: "utf-8",
        name: {
          keywords: "Order History",
        },
      },
    };

    const getSources = (item) => {
      return item.source.map((source) => {
        return source.description
      }).join()
    }

    const getPaymentTerm = (item) => {
      let color = item.payment_term.status === "invoiced" ? " #479667" : "#EF9643";
      return (
        <span style={{ color: color, fontSize: 14, fontWeight: 600, overflowWrap: 'break-word', wordWrap: 'break-word' }}>
          {payment_term[item.payment_term]}
        </span>
      );
    }
    const onOrderSelect = (e) => {
      const index = _.indexOf(this.state.selectedRowKeys, e.target.value);
      if (e.target.checked) {
        this.setState({ selectedRowKeys: [...this.state.selectedRowKeys, e.target.value] })
      } else {
        const updatedSelectedRowKeys = this.state.selectedRowKeys;
        this.setState({ selectedRowKeys: updatedSelectedRowKeys.splice(index, 1) }, () => {
          this.onSelectChange(updatedSelectedRowKeys);
        });
      }
    }

    const onUploadAttachment = (fileList, orderId) => {
      uploadOrderAttachment(orderId, fileList).then((response) => {
        if (response.error) {
          return message.error('Unable to upload attachment');
        }

        message.success('Successfully uploaded attachment');
        this.fetchOrders()
      })
    }

    if (distributor.data && distributor.data.customer.customer_group) {
      columns = columns.filter((value) => !_.includes(['CUSTOMER', 'CUSTOMER GROUP', 'CUSTOMER GROUP 2'], value.title))
    }

    return (
      <DocumentMeta {...meta}>
        <Layout>
          <Layout.Content className="order-status-content">
            <Row className="order-status-row">
              {/* Order List Headers START*/}
              <Col span={24}>
                <MediaQuery minWidth={1224}>
                  <Row className='summary-header-first'>
                    <Col span={8}>
                      <h1>Order History</h1>
                    </Col>
                    <Col span={16}>
                      <Row className='summary-header-second'>
                        <Row>
                          <Col span={6}>
                            <div className='total-text'>
                              <h1>TOTAL ORDERS   <span>
                                {orderSummary?.total_order || 0}
                              </span></h1>
                            </div>
                          </Col>
                          <Col span={6}>
                            <div className='total-text'>
                              <h1>CONFIRMED<span align="right" style={{ marginLeft: '85%' }}>
                                {orderSummary?.total_confirmed || 0}
                              </span></h1>
                            </div>
                          </Col>
                          <Col span={6}>
                            <div className='total-text'>
                              <h1>APPROVED<span style={{ marginLeft: '22%' }}>
                                {orderSummary?.total_approved || 0}
                              </span></h1>
                            </div>
                          </Col>
                          <Col span={6}>
                            <div className='total-text'>
                              <h1>BLOCKED   <span style={{ marginLeft: '22%' }}>
                                {orderSummary?.total_blocked || 0}
                              </span></h1>
                            </div>
                          </Col>
                        </Row>
                        <Row>
                          <Col span={6}>

                          </Col>
                          <Col span={6}>
                            <div className='total-text'>
                              <h1>PARTIALLY DISPATCHED   <span>
                                {orderSummary?.total_partially_dispatched || 0}
                              </span></h1>
                            </div>
                          </Col>
                          <Col span={6}>
                            <div className='total-text'>
                              <h1>COMPLETED   <span>
                                {orderSummary?.total_completed || 0}
                              </span></h1>
                            </div>
                          </Col>
                          <Col span={6}>
                            <div className='total-text'>
                              <h1>CANCELLED<span>
                                {orderSummary?.total_cancelled || 0}
                              </span></h1>
                            </div>
                          </Col>
                        </Row>
                      </Row>
                    </Col>
                  </Row>
                </MediaQuery>
                <MediaQuery maxWidth={1224} minWidth={601}>
                  <Row className='summary-header-first'>
                    <Col span={8}>
                      <h1>Order History</h1>
                    </Col>
                    <Col span={16}>
                      <Row className='summary-header-second'>
                        <Col span={12}>
                          <div className='total-text'>
                            <h1>TOTAL ORDERS   <span>
                              {orderSummary?.total_order || 0}
                            </span></h1>
                          </div>
                        </Col>
                        {/* <Col span={12}>
                          <div className='total-text'>
                            <h1>SUBMITTED
                              <span>{orderSummary?.total_checkout || 0}</span>
                            </h1>
                          </div>
                        </Col> */}
                        <Col span={12}>
                          <div className='total-text'>
                            <h1>CONFIRMED    <span>
                              {orderSummary?.total_confirmed || 0}
                            </span></h1>
                          </div>
                        </Col>

                        <Col span={12}>
                          <div className='total-text'>
                            <h1>BLOCKED   <span style={{ marginLeft: '50%' }}>
                              {orderSummary?.total_blocked || 0}
                            </span></h1>
                          </div>
                        </Col>
                        <Col span={12}>
                          <div className='total-text'>
                            <h1>PARTIALLY DISPATCHED   <span>
                              {orderSummary?.total_partially_dispatched || 0}
                            </span></h1>
                          </div>
                        </Col>
                        <Col span={12}>
                          <div className='total-text'>
                            <h1>COMPLETED   <span>
                              {orderSummary?.total_completed || 0}
                            </span></h1>
                          </div>
                        </Col>

                        <Col span={12}>
                          <div className='total-text'>
                            <h1>CANCELLED<span>
                              {orderSummary?.total_cancelled || 0}
                            </span></h1>
                          </div>
                        </Col>
                        <Col span={12}>
                          <div className='total-text'>
                            <h1>APPROVED   <span>
                              {orderSummary?.total_approved || 0}
                            </span></h1>
                          </div>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                </MediaQuery>
                <MediaQuery maxWidth={600}>
                  <Row className='summary-header-first'>
                    <Col span={24}>
                      <h1>Order History</h1>
                    </Col>
                    <Col span={24}>
                      <Row className='summary-header-second'>
                        <Col span={24}>
                          <div className='total-text'>
                            <h1>TOTAL ORDERS   <span>
                              {orderSummary?.total_order || 0}
                            </span></h1>
                          </div>
                        </Col>
                        {/* <Col span={24}>
                          <div className='total-text'>
                            <h1>SUBMITTED
                              <span>{orderSummary?.total_checkout || 0}</span>
                            </h1>
                          </div>
                        </Col> */}
                        <Col span={24}>
                          <div className='total-text'>
                            <h1>CONFIRMED    <span>
                              {orderSummary?.total_confirmed || 0}
                            </span></h1>
                          </div>
                        </Col>

                        <Col span={24}>
                          <div className='total-text'>
                            <h1>BLOCKED   <span style={{ marginLeft: '50%' }}>
                              {orderSummary?.total_blocked || 0}
                            </span></h1>
                          </div>
                        </Col>
                        <Col span={24}>
                          <div className='total-text'>
                            <h1>PARTIALLY DISPATCHED   <span>
                              {orderSummary?.total_partially_dispatched || 0}
                            </span></h1>
                          </div>
                        </Col>
                        <Col span={24}>
                          <div className='total-text'>
                            <h1>COMPLETED   <span>
                              {orderSummary?.total_completed || 0}
                            </span></h1>
                          </div>
                        </Col>

                        <Col span={24}>
                          <div className='total-text'>
                            <h1>CANCELLED<span>
                              {orderSummary?.total_cancelled || 0}
                            </span></h1>
                          </div>
                        </Col>
                        <Col span={24}>
                          <div className='total-text'>
                            <h1>APPROVED   <span>
                              {orderSummary?.total_approved || 0}
                            </span></h1>
                          </div>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                </MediaQuery>
              </Col>
              {/* Order List Headers END*/}

              {/* Order List Filters START*/}
              <Col span={24}>
                <MediaQuery minWidth={1024}>
                  <Row type="flex" className="order-status-filters">
                    <Col md={10} lg={10} className="search-container">
                      {['order_detail.created_at', 'delivery_details.estimated_delivery_date'].includes(filterBy) ?
                        <RangePicker className='search-date-container'
                          style={{ width: 300 }}
                          size="large"
                          onChange={(date, dateString) => this.handleSearch(dateString, filterBy)} />

                        :
                        <Input
                          type="text"
                          style={{ width: 300 }}
                          prefix={<Icon type="search" />}
                          placeholder="Search"
                          size="large"
                          value={keyword}
                          onChange={(e) =>
                            this.handleSearch(
                              e.target.value,
                              filterBy
                            )
                          }
                        />
                      }
                      <Select
                        style={{ width: 150 }}
                        placeholder="Filter"
                        size="large"
                        defaultValue={filterByValue}
                        dropdownClassName="recent-order-select"
                        onChange={(value) => this.handleFilterBy(value)}
                        showSearch
                      >
                        {/* <Option value="all">All</Option> */}

                        {columns.map((value) => (
                          <Option key={value.key} value={value.key}>
                            {value.key}
                          </Option>
                        )).filter(value => !this.excludedColumns.includes(value.key))}
                      </Select>
                    </Col>
                    <Col>
                      <div>
                        <span style={{ marginRight: '15px' }}>Source: </span>
                        <Select defaultValue={'all'} onSelect={this.handleSourceFilter} showSearch style={{ width: 230 }}>
                          <Option value='all' key="all">All</Option>
                          {storageLocationData.map((value) => <Option value={value.id} key={value.id}>{value.description}</Option>)}

                        </Select>
                      </div>
                    </Col>
                    <Col xs={24} sm={24} md={14} lg={14} style={{ display: 'flex', flex: 1, justifyContent: 'flex-end' }}>
                      <Col xs={24} sm={24} md={7} lg={7} style={{ marginRight: 10 }}>
                        <Button
                          className="btn-small btn-secondary-gold"
                          disabled={
                            selectedRowKeys.length === 0
                          }
                          onClick={() =>
                            this.setState({ showDownloadModal: true })
                          }
                          style={{ width: "100%" }}
                        >
                          <Icon type="line-chart" />Extract Report
                        </Button>
                      </Col>
                    </Col>
                  </Row>
                  <Row type="flex" style={{ padding: 15, gap: 10 }}>
                    <label className="radio">
                      ALL
                      <input
                        style={{ margin: "5px 10px" }}
                        type="radio"
                        name="order_detail.status"
                        checked={radio === "all"}
                        value="all"
                        onChange={(e) => {
                          delete this.sortState.filters;
                          this.setState({ page: 1 })
                          this.props.fetchOrderStatus(this.orderStatusArray, {
                            ...this.sortState, ...{
                              page: 1
                            }, ...this.searchState, ...this.jsonFilter
                          })

                          this.setState({ radio: e.target.value });
                        }}
                      />
                      <span className="checkmark"></span>
                    </label>
                    <label className="radio">
                      CONFIRMED
                      <input
                        style={{ margin: "5px 10px" }}
                        type="radio"
                        name="status"
                        checked={radio === "Confirmed"}
                        value="Confirmed"
                        onChange={(e) => {
                          this.sortState.filters = JSON.stringify({ 'order_detail.status': ['Confirmed', "For Salesman Approval", "For Review"] })
                          this.setState({ page: 1 })
                          this.props.fetchOrderStatus(this.orderStatusArray, {
                            ...this.sortState, ...{
                              page: 1
                            }, ...this.searchState, ...this.jsonFilter
                          })

                          this.setState({ radio: e.target.value });
                        }}
                      />
                      <span className="checkmark"></span>
                    </label>
                    <label className="radio">
                      APPROVED
                      <input
                        style={{ margin: "5px 10px" }}
                        type="radio"
                        name="status"
                        checked={radio === "Approved"}
                        value="Approved"
                        onChange={(e) => {
                          this.sortState.filters = JSON.stringify({ 'order_detail.status': ['Approved'] })
                          this.setState({ page: 1 })
                          this.props.fetchOrderStatus(this.orderStatusArray, {
                            ...this.sortState, ...{
                              page: 1
                            }, ...this.searchState, ...this.jsonFilter
                          })

                          this.setState({ radio: e.target.value });
                        }}
                      />
                      <span className="checkmark"></span>
                    </label>
                    <label className="radio">
                      BLOCKED
                      <input
                        style={{ margin: "5px 10px" }}
                        type="radio"
                        name="status"
                        checked={radio === "Blocked"}
                        value="Blocked"
                        onChange={(e) => {
                          this.sortState.filters = JSON.stringify({ 'order_detail.status': ['Blocked'] })
                          this.setState({ page: 1 })
                          this.props.fetchOrderStatus(this.orderStatusArray, {
                            ...this.sortState, ...{
                              page: 1
                            }, ...this.searchState, ...this.jsonFilter
                          })

                          this.setState({ radio: e.target.value });
                        }}
                      />
                      <span className="checkmark"></span>
                    </label>
                    <label className="radio">
                      PARTIALLY DISPATCHED
                      <input
                        style={{ margin: "5px 10px" }}
                        type="radio"
                        name="status"
                        checked={radio === "Partially Dispatched"}
                        value="Partially Dispatched"
                        onChange={(e) => {
                          this.sortState.filters = JSON.stringify({ 'order_detail.status': ['Partially Dispatched'] })
                          this.setState({ page: 1 })
                          this.props.fetchOrderStatus(this.orderStatusArray, {
                            ...this.sortState, ...{
                              page: 1
                            }, ...this.searchState, ...this.jsonFilter
                          })

                          this.setState({ radio: e.target.value });
                        }}
                      />
                      <span className="checkmark"></span>
                    </label>
                    <label className="radio">
                      COMPLETED
                      <input
                        style={{ margin: "5px 10px" }}
                        type="radio"
                        name="status"
                        checked={radio === "Completed"}
                        value="Completed"
                        onChange={(e) => {
                          this.sortState.filters = JSON.stringify({ 'order_detail.status': ['Completed'] })
                          this.setState({ page: 1 })
                          this.props.fetchOrderStatus(this.orderStatusArray, {
                            ...this.sortState, ...{
                              page: 1
                            }, ...this.searchState, ...this.jsonFilter
                          })

                          this.setState({ radio: e.target.value });
                        }}
                      />
                      <span className="checkmark"></span>
                    </label>
                    <label className="radio">
                      CANCELLED
                      <input
                        style={{ margin: "5px 10px" }}
                        type="radio"
                        name="status"
                        checked={radio === "Cancelled"}
                        value="Cancelled"
                        onChange={(e) => {
                          this.sortState.filters = JSON.stringify({ 'order_detail.status': ['Cancelled'] })
                          this.setState({ page: 1 })
                          this.props.fetchOrderStatus(this.orderStatusArray, {
                            ...this.sortState, ...{
                              page: 1
                            }, ...this.searchState, ...this.jsonFilter
                          })

                          this.setState({ radio: e.target.value });
                        }}
                      />
                      <span className="checkmark"></span>
                    </label>
                  </Row>
                </MediaQuery>
                <MediaQuery maxWidth={1023}>
                  <Row type="flex" className="order-status-filters" >
                    <Col span={24} className="search-container">
                      {['order_detail.created_at', 'distributor_orders.estimated_delivery'].includes(filterBy) ?
                        <RangePicker className='search-date-container'
                          style={{ width: "50%" }}
                          size="large"
                          onChange={(date, dateString) => this.handleSearch(dateString, filterBy)} />

                        :
                        <Input
                          type="text"
                          prefix={<Icon type="search" />}
                          placeholder="Search Products"
                          size="large"
                          value={keyword}
                          style={{ width: 300 }}
                          onChange={(e) =>
                            this.handleSearch(
                              e.target.value,
                              filterBy === "all"
                                ? columns.map((value) => value.dataIndex)
                                : [filterBy]
                            )
                          }
                        />
                      }
                      <Select
                        style={{ width: 150 }}
                        placeholder="Filter"
                        size="large"
                        defaultValue={filterByValue}
                        dropdownClassName="recent-order-select"
                        onChange={(value) => this.handleFilterBy(value)}
                        showSearch
                      >
                        {/* <Option value="all">All</Option> */}

                        {columns.map((value) => (
                          <Option key={value.key} value={value.key}>
                            {value.key}
                          </Option>
                        )).filter(value => !this.excludedColumns.includes(value.key))}
                      </Select>
                      <Content className='content-filter'>
                        <div>Source:</div>
                        <div>
                          <Select defaultValue={'all'} showSearch style={{ width: 230 }}>
                            <Option value='all' key="all">All</Option>
                            {storageLocationData.map((value) => <Option value={value.id} key={value.id}>{value.description}</Option>)}

                          </Select>
                        </div>
                      </Content>
                      <Col span={24}>
                        <Row type="flex" justify="end" gutter={22} style={{ gap: '12px', marginTop: "12px" }}>
                          <Col span={24}>
                            <Button
                              className="btn-small btn-secondary-gold"
                              disabled={
                                !myPermissions.some(
                                  (r) => r.name === "distributor.orders.download"
                                )
                              }
                              onClick={() =>
                                this.setState({ showDownloadModal: true })
                              }
                              style={{ width: "100%" }}
                            >
                              <Icon type="line-chart" />
                              Extract Report
                            </Button>
                          </Col>
                        </Row>
                      </Col>
                    </Col>
                  </Row>
                  <Row type="flex" style={{ padding: 15 }}>
                    <label className="radio">
                      ALL
                      <input
                        type="radio"
                        name="status"
                        checked={radio === "all"}
                        value="all"
                        onChange={(e) => {
                          delete this.sortState.filters;
                          this.setState({ page: 1 })
                          this.props.fetchOrderStatus(this.orderStatusArray, {
                            ...this.sortState, ...{
                              page: 1
                            }, ...this.searchState, ...this.jsonFilter
                          })

                          this.setState({ radio: e.target.value });
                        }}
                      />
                      <span className="checkmark"></span>
                    </label>
                    {/* <label className="radio">
                      SUBMITTED
                      <input
                        type="radio"
                        name="status"
                        checked={radio === "Submitted"}
                        value="Submitted"
                        onChange={(e) => {
                          this.sortState.filters = JSON.stringify({ 'status': ['Submitted'] })
                          this.props.fetchOrderStatus(this.orderStatusArray, {
                            ...this.sortState, ...{
                              page: 1
                            }, ...this.searchState
                          })

                          this.setState({ radio: e.target.value });
                        }}
                      />
                      <span className="checkmark"></span>
                    </label> */}
                    <label className="radio">
                      CONFIRMED
                      <input
                        type="radio"
                        name="status"
                        checked={radio === "Confirmed"}
                        value="Confirmed"
                        onChange={(e) => {
                          this.sortState.filters = JSON.stringify({ 'order_detail.status': ['Confirmed'] })
                          this.setState({ page: 1 })
                          this.props.fetchOrderStatus(this.orderStatusArray, {
                            ...this.sortState, ...{
                              page: 1
                            }, ...this.searchState, ...this.jsonFilter
                          })

                          this.setState({ radio: e.target.value });
                        }}
                      />
                      <span className="checkmark"></span>
                    </label>
                    <label className="radio">
                      APPROVED
                      <input
                        type="radio"
                        name="status"
                        checked={radio === "Approved"}
                        value="Approved"
                        onChange={(e) => {
                          this.sortState.filters = JSON.stringify({ 'order_detail.status': ['Approved'] })
                          this.setState({ page: 1 })
                          this.props.fetchOrderStatus(this.orderStatusArray, {
                            ...this.sortState, ...{
                              page: 1
                            }, ...this.searchState, ...this.jsonFilter
                          })

                          this.setState({ radio: e.target.value });
                        }}
                      />
                      <span className="checkmark"></span>
                    </label>
                    <label className="radio">
                      BLOCKED
                      <input
                        type="radio"
                        name="status"
                        checked={radio === "Blocked"}
                        value="Blocked"
                        onChange={(e) => {
                          this.sortState.filters = JSON.stringify({ 'order_detail.status': ['Blocked'] })
                          this.setState({ page: 1 })
                          this.props.fetchOrderStatus(this.orderStatusArray, {
                            ...this.sortState, ...{
                              page: 1
                            }, ...this.searchState, ...this.jsonFilter
                          })

                          this.setState({ radio: e.target.value });
                        }}
                      />
                      <span className="checkmark"></span>
                    </label>
                    <label className="radio">
                      PARTIALLY DISPATCHED
                      <input
                        type="radio"
                        name="status"
                        checked={radio === "Partially Dispatched"}
                        value="Partially Dispatched"
                        onChange={(e) => {
                          this.sortState.filters = JSON.stringify({ 'order_detail.status': ['Partially Dispatched'] })
                          this.setState({ page: 1 })
                          this.props.fetchOrderStatus(this.orderStatusArray, {
                            ...this.sortState, ...{
                              page: 1
                            }, ...this.searchState, ...this.jsonFilter
                          })

                          this.setState({ radio: e.target.value });
                        }}
                      />
                      <span className="checkmark"></span>
                    </label>
                    <label className="radio">
                      COMPLETED
                      <input
                        type="radio"
                        name="status"
                        checked={radio === "Completed"}
                        value="Completed"
                        onChange={(e) => {
                          this.sortState.filters = JSON.stringify({ 'order_detail.status': ['Completed'] })
                          this.setState({ page: 1 })
                          this.props.fetchOrderStatus(this.orderStatusArray, {
                            ...this.sortState, ...{
                              page: 1
                            }, ...this.searchState, ...this.jsonFilter
                          })

                          this.setState({ radio: e.target.value });
                        }}
                      />
                      <span className="checkmark"></span>
                    </label>
                    <label className="radio">
                      CANCELLED
                      <input
                        type="radio"
                        name="status"
                        checked={radio === "Cancelled"}
                        value="Cancelled"
                        onChange={(e) => {
                          this.sortState.filters = JSON.stringify({ 'order_detail.status': ['Cancelled'] })
                          this.setState({ page: 1 })
                          this.props.fetchOrderStatus(this.orderStatusArray, {
                            ...this.sortState, ...{
                              page: 1
                            }, ...this.searchState, ...this.jsonFilter
                          })

                          this.setState({ radio: e.target.value });
                        }}
                      />
                      <span className="checkmark"></span>
                    </label>
                  </Row>
                </MediaQuery>
              </Col>
              {/* Order List Filters END*/}

              {/* Order List START*/}
              <MediaQuery minWidth={1024}>
                <Col span={24} className="order-status-table">
                  <Table
                    rowSelection={rowSelection}
                    dataSource={orderStatusData}
                    columns={columns}
                    components={{

                      header: {
                        row: (props) => {
                          return (
                            <tr style={{ width: 'auto !important' }}>
                              {props.children.filter(child => child.key !== 'poCreatedAtWhole')}
                            </tr>
                          );
                        }
                      },

                    }}
                    rowKey="id"
                    style={{ overflow: "hidden", width: "100%", zIndex: 0 }}
                    loading={isLoading}
                    onRowClick={(record) => {
                      const {
                        id
                      } = record;

                      this.props.history.push(
                        `purchase-status?id=${id}`
                      );
                    }}
                    onChange={(pagination, filters, sorter) => {
                      // document.body.scrollTop = 0; // For Safari
                      // document.documentElement.scrollTop = 0;
                      if (!_.isEmpty(sorter)) {
                        this.sortState = {
                          sortBy: this.handleFilterBy(sorter.columnKey, false) || this.sortState.sortBy,
                          orderBy: sorter.order === 'descend' ? 'desc' : 'asc',
                          filters: this.sortState.filters
                        }

                        if (!this.sortState.filters) delete this.sortState.filters;

                        if (!sorter.order) {
                          ['sortBy', 'orderBy'].forEach(key => delete this.sortState[key])
                        }
                      }

                      this.setState({ page: 1 })

                      this.props.fetchOrderStatus(this.orderStatusArray, {
                        ...this.sortState, ...{
                          page: pagination.current
                        }, ...this.searchState, ...this.jsonFilter
                      });
                    }}
                    pagination={{ total: dataSource.total, current: dataSource.current_page, pageSize: dataSource.per_page || 10 }}
                  />
                </Col>
              </MediaQuery>
              <MediaQuery maxWidth={1023}>
                <Col span={24}>
                  <List
                    size="large"
                    loading={isLoading}
                    bordered
                    dataSource={orderStatusData}
                    style={{ padding: '0px 12px' }}
                    renderItem={(item) => (
                      <List.Item key={item.materialCode}>
                        <Row style={{ width: "100%", paddingTop: '20px', paddingBottom: '20px' }} className="order-status-xs-content">
                          <Col span={24}>
                            <label for={item.id}>
                              <Row>
                                <Col span={12}>
                                  <h4>ORDER DATE</h4>
                                </Col>
                                <Col span={11}>
                                  <h4>{moment(item.created_at).format('YYYY-MM-DD') ?? '-'}</h4>
                                </Col>
                                <Col span={1}>
                                  <Checkbox id={item.id} onChange={onOrderSelect} value={item.id} name="test" />
                                </Col>
                              </Row>
                              <Row>
                                <Col span={12}>
                                  <h4>ORDER NUMBER</h4>

                                </Col>
                                <Col span={12}>
                                  {item.order_transaction_id ? (
                                    <Link to={`/purchase-status?id=${item.id}`}>{item.order_transaction_id}</Link>
                                  ) : '-'}
                                </Col>
                              </Row>
                              <Row>
                                <Col span={12}>
                                  <h4>TRANSACTION REFERENCE NUMBER</h4>
                                </Col>
                                <Col span={12}>
                                  <h4>{item.sales_order_number ?? '-'}</h4>
                                </Col>
                              </Row>
                              <Row>
                                <Col span={12}>
                                  <h4>ORDER STATUS</h4>
                                </Col>
                                <Col span={12}>
                                  <h4>{item.status ?? '-'}</h4>
                                </Col>
                              </Row>
                              {
                                distributor.data && distributor.data.customer.customer_group ? "" : (
                                  <div>
                                    <Row>
                                      <Col span={12}>
                                        <h4>CUSTOMER</h4>
                                      </Col>
                                      <Col span={12}>
                                        <h4>{item.customer.name ?? '-'}</h4>
                                      </Col>
                                    </Row>
                                    <Row>
                                      <Col span={12}>
                                        <h4>CUSTOMER GROUP</h4>
                                      </Col>
                                      <Col span={12}>
                                        <h4>{item.customer.customer_group ?? '-'}</h4>
                                      </Col>
                                    </Row>
                                    <Row>
                                      <Col span={12}>
                                        <h4>CUSTOMER GROUP 2</h4>
                                      </Col>
                                      <Col span={12}>
                                        <h4>{item.customer.customer_group_2 ?? '-'}</h4>
                                      </Col>
                                    </Row>
                                  </div>
                                )
                              }
                              <Row>
                                <Col span={12}>
                                  <h4>DELIVERED TO</h4>
                                </Col>
                                <Col span={12}>
                                  <h4>{item.sold_to.address ?? '-'}</h4>
                                </Col>
                              </Row>
                              <Row>
                                <Col span={12}>
                                  <h4>ORDERED QUANTITY</h4>
                                </Col>
                                <Col span={12}>
                                  <h4>{item.total_quantity ?? '-'}</h4>
                                </Col>
                              </Row>
                              <Row>
                                <Col span={12}>
                                  <h4>QUANTITY (IN KG)</h4>
                                </Col>
                                <Col span={12}>
                                  <h4>{() => {
                                    const total_weight = item.order_material.reduce(function (res, item) {
                                      return res + (item.weight);
                                    }, 0);
                                    return numFormat("dot/comma", total_weight || 0) ?? '-'
                                  }}</h4>
                                </Col>
                              </Row>
                              <Row>
                                <Col span={12}>
                                  <h4>ORDERED AMOUNT (PHP VAT-IN)</h4>
                                </Col>
                                <Col span={12}>
                                  <h4> PHP {numFormat("dot/comma", (item.order_price?.vat_sales + item.order_price?.vat_amount)) || '0.00'}</h4>
                                </Col>
                              </Row>
                              <Row>
                                <Col span={12}>
                                  <h4>AMOUNT FOR PAYMENT</h4>
                                </Col>
                                <Col span={12}>
                                  <h4> PHP {numFormat("dot/comma", item.order_price?.total_amount - item.order_price?.ewt_amount) || "0.00"}</h4>
                                </Col>
                              </Row>
                              <Row>
                                <Col span={12}>
                                  <h4>DELIVERY DATE</h4>
                                </Col>
                                <Col span={12}>
                                  <h4>{item.delivery_details?.estimated_delivery_date ?? '-'}</h4>
                                </Col>
                              </Row>
                              <Row>
                                <Col span={12}>
                                  <h4>TYPE</h4>
                                </Col>
                                <Col span={12}>
                                  <h4>{item.delivery_details?.type ?? '-'}</h4>
                                </Col>
                              </Row>
                              <Row>
                                <Col span={12}>
                                  <h4>SOURCE</h4>
                                </Col>
                                <Col span={12}>
                                  <h4>{getSources(item)}</h4>
                                </Col>
                              </Row>
                              <Row>
                                <Col span={12}>
                                  <h4>PAYMENT TERM</h4>
                                </Col>
                                <Col span={12}>
                                  {getPaymentTerm(item)}
                                </Col>
                              </Row>
                            </label>
                          </Col>
                        </Row>
                      </List.Item>
                    )}
                    pagination={{
                      total: dataSource.total, current: dataSource.current_page, pageSize: dataSource.per_page || 10,
                      onChange: page => {
                        document.body.scrollTop = 0; // For Safari
                        document.documentElement.scrollTop = 0;
                        this.setState({ page: 1 })
                        this.props.fetchOrderStatus(this.orderStatusArray, {
                          ...this.sortState, ...{
                            page: page
                          }, ...this.searchState, ...this.jsonFilter
                        });
                      }
                    }}
                  />
                </Col>
              </MediaQuery>
              {/* Order List END*/}
            </Row>
          </Layout.Content>

          <Modal
            className="feedback-modal"
            title={<span>We'd like to get your feedback</span>}
            visible={showFeedbackModal}
            onCancel={() => {
              this.setState({ showFeedbackModal: false });
              this.props.history.push("/order-status");
            }}
            footer={null}
            maskClosable={false}
            closable={false}
          >
            <h3>How was your overall order experience?</h3>
            <Row
              type="flex"
              justify="space-between"
              align="middle"
              className="feedback-rate"
            >
              {feedbackEmoji.map((value, i) => (
                <Col
                  key={i}
                  onClick={() => this.handleClickRate(value.rate)}
                  className={value.rate === activeRate ? "active" : ""}
                >
                  <img alt={value.rate} src={value.svg} />
                </Col>
              ))}
            </Row>
            {/* {showQuestion ? ( */}
            <form>
              <Row className="feedback-question">
                <Col span={24}>
                  <h3>Any comments/suggestions?</h3>
                  <div className="form-label">
                    <Field
                      name={`comments`}
                      className="inputStyle"
                      component={renderInput}
                      validate={feedbackValidator}
                    />
                  </div>
                </Col>
              </Row>
              <Row type="flex" justify="end" className="feedback-button">
                <Col>
                  <Button
                    htmlType="submit"
                    style={{ width: "192px" }}
                    className="btn-small btn-primary"
                    loading={isPostingFeedback}
                    onClick={handleSubmit((formData) => {
                      this.handleFeedbackSubmit(formData);
                    })}
                  >
                    {isPostingFeedback ? null : "Send Feedback Form"}
                  </Button>
                </Col>
              </Row>
            </form>
            {/* ) : null} */}
          </Modal>

          <Modal
            className="feedback-modal"
            title={<span>Feedback Received!</span>}
            visible={showThankYouModal}
            onCancel={() => this.setState({ showThankYouModal: false })}
            footer={null}
            closable={false}
          >
            <h3>Thank you for your feedback!</h3>
            <p>
              Rest assured that we are continuously enhancing your order
              experience with us.
            </p>
            <Row type="flex" justify="end" className="feedback-button">
              <Col span={24} align="right">
                <Button
                  style={{ margin: 0, minWidth: "80px" }}
                  className="btn-small btn-primary"
                  onClick={() => this.setState({ showThankYouModal: false })}
                >
                  Ok
                </Button>
              </Col>
            </Row>
          </Modal>

          <Modal
            className="feedback-modal"
            title={<span>Order Status Updated</span>}
            visible={showStatusUpdateModal}
            onCancel={() => {
              this.setState({ showStatusUpdateModal: false });
              this.props.history.push("/order-status");
            }}
            footer={null}
            maskClosable={false}
            closable={false}
          >
            <h3>
              Your order {urlParams.get("po_number") ? urlParams.get("po_number") : ''} was already INVOICED!
            </h3>
            <p>
              We'd like to get your feedback on your order experience with us.
              Please click the Feedback Form button below.
            </p>

            <Row type="flex" justify="end" className="feedback-button">
              <Col>
                <Button
                  htmlType="submit"
                  className="btn-small btn-primary"
                  onClick={() =>
                    this.setState({
                      showFeedbackModal: true,
                      showStatusUpdateModal: false,
                    })
                  }
                >
                  Feedback Form
                </Button>
              </Col>
            </Row>
          </Modal>

          <Modal
            title="Download Options"
            visible={showDownloadModal}
            width={350}
            onCancel={() => this.setState({ showDownloadModal: false })}
            footer={null}
            className="download-option-modal"
          >
            <Checkbox
              indeterminate={this.state.indeterminate}
              onChange={this.onCheckAllChange}
              checked={this.state.checkAll}
            >
              All
            </Checkbox>
            <br />
            <CheckboxGroup
              options={plainOptions}
              value={checkedList}
              onChange={this.onChangeCheckbox}
            />
            <Row>
              <Col span={24}><Button type="link" onClick={() => this.setState({ checkedList: [], indeterminate: false, checkAll: false })}>Clear All</Button></Col>
            </Row>

            <Row type="flex" style={{ margin: "10px 0px" }}>
              <Col span={12}><Button className="btn-small btn-default" onClick={() => this.setState({ showDownloadModal: false, checkedList: [], indeterminate: false, checkAll: false })}>Cancel</Button></Col>
              <Col span={12}><Button className="btn-small btn-primary" disabled={checkedList.length < 1} onClick={() => this.download(checkedList, downloadDataArray)}>Download</Button></Col>
            </Row>
          </Modal>

          <ModalAttachment isVisible={showModalAttachment} onClose={this.changeAttachmentListVisibility} selectedOrder={_.find(tableData.data, { "id": selectedId })} onRemove={removeOrderAttachment} onReload={this.fetchOrders} onSubmitCallback={onUploadAttachment} onDownload={downloadAttachment} onPreview={retrieveFileUrl} />
        </Layout>
      </DocumentMeta >
    );
  }
}

const mapStateToProps = (state) => {
  const {
    permissions,
    recentOrders,
    distributor,
    orderStatus,
    sapData,
    notifications,
    poStatus,
    distributorOrderSummary
  } = state;

  return {
    myPermissions: permissions.data,
    myPermissionsFetching: permissions.fetching,
    recentOrders: recentOrders,
    distributor,
    orderStatus,
    distributorOrderSummary,
    sapData,
    poStatus,
    downloadFile,
    notificationsData: notifications,
    fetching: notifications.fetching,
  };
};

export default connect(mapStateToProps, {
  fetchPermissions,
  fetchDistributor,
  fetchRecentOrders,
  fetchOrderStatus,
  fetchDataFromSap,
  fetchPoStatus,
  fetchNotifications,
  postPoNumber,
  postFeedback,
  approveRequest,
  rejectRequest,
  fetchDistributorOrderSummary,
  loadStorageLocation,
  removeOrderAttachment,
  uploadOrderAttachment,
  downloadAttachment,
  retrieveFileUrl
})(
  reduxForm({ form: "orderStatus", enableReinitialize: true })(RecentOrder)
);
